import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../shared/constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from '../shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cartValue = new BehaviorSubject<any>(0.00);
  getCartDetail = new BehaviorSubject<any>(false);
  onCartChange = new BehaviorSubject<any>(false);
  InterceptorSkipLoader = 'X-Skip-Loader';
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
  }


  // Update cart value
  updateCartValue(value) {
    this.cartValue.next(value);
  }

  getCartValue(): Observable<any> {
    return this.cartValue.asObservable();
  }

  // Update cart Detail Page
  updateCartDetailPage(value) {
    this.getCartDetail.next(value);
  }

  getCartDetailUpdateStatus(): Observable<any> {
    return this.getCartDetail.asObservable();
  }

  // When a zip code is entered into this text box --> “Enter your zip code to calculate”
  // If logged in call on load
  getShippingMethods(body): Observable<any> {
    return this.http.get(`${Constants.baseURL}/shippingMethod?${body}`);
  }

  getShippingMethodsPromise(body): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.http.get(`${Constants.baseURL}/shippingMethod?${body}`).toPromise());
    });
  }

  getShoppingCartList(): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/shoppingCarts`).pipe(
      map(data => {
        return this.handleCartNotFound(data);
      }));
  }

  getShoppingCartById(cartId): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/shoppingCarts/${cartId}`).pipe(
      map(data => {
        return this.handleCartNotFound(data);
      }));
  }

  getShoppingCartByIdWithoutInterceptor(cartId): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/shoppingCarts/${cartId}`, { headers }).pipe(
      map(data => {
        return this.handleCartNotFound(data);
      }));
  }

  getShoppingCartListWithoutAccount(): Observable<any> {
    return this.http.get(`${Constants.baseURL}/shoppingCarts`).pipe(
      map(data => {
        return this.handleCartNotFound(data);
      }));
  }

  getShoppingCartByIdWithoutAccount(cartId): Observable<any> {
    return this.http.get(`${Constants.baseURL}/shoppingCarts/${cartId}`).pipe(
      map(data => {
        return this.handleCartNotFound(data);
      }));
  }

  getShoppingCartByIdWithoutAccountWithoutInterceptor(cartId): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    return this.http.get(`${Constants.baseURL}/shoppingCarts/${cartId}`, { headers }).pipe(
      map(data => {
        return this.handleCartNotFound(data);
      }));
  }

  getShoppingCartSkusList(cartId): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/shoppingCarts/${cartId}/skus`).pipe(
      map(data => {
        return this.handleCartNotFound(data);
      }));
  }

  getShoppingCartSkusById(cartId, skuId): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/shoppingCarts/${cartId}/skus/${skuId}`).pipe(
      map(data => {
        return this.handleCartNotFound(data);
      }));
  }

  handleCartNotFound(data): Observable<any> {
    if (data && data.message === Constants.resourceNotFound) {
      this.storageService.removeItem('cartId');
    }
    return data;
  }
  createShoppingCartWithoutAccount(body): Observable<any> {
    return this.http.post(`${Constants.baseURL}/shoppingCarts`, body);
  }
  createShoppingCartSkuWithoutAccount(cartId, body): Observable<any> {
    return this.http.post(`${Constants.baseURL}/shoppingCarts/${cartId}/skus`, body);
  }
  createShoppingCart(body): Observable<any> {
    return this.http.post(`${Constants.baseURL}/accounts/account/customer/shoppingCarts`, body);
  }
  createShoppingCartSku(cartId, body): Observable<any> {
    return this.http.post(`${Constants.baseURL}/accounts/account/customer/shoppingCarts/${cartId}/skus`, body);
  }
  updateShoppingCartByIdWithoutAccount(cartId, body): Observable<any> {
    return this.http.put(`${Constants.baseURL}/shoppingCarts/${cartId}`, body);
  }
  addAllToShoppingCartByIdWithoutAccount(cartId, body): Observable<any> {
    return this.http.put(`${Constants.baseURL}/shoppingCarts/${cartId}/skus`, body);
  }
  updateShoppingCartSkusByIdWithoutAccount(cartId, skuId, body): Observable<any> {
    return this.http.put(`${Constants.baseURL}/shoppingCarts/${cartId}/skus/${skuId}`, body);
  }
  updateShoppingCartById(cartId, body): Observable<any> {
    return this.http.put(`${Constants.baseURL}/accounts/account/customer/shoppingCarts/${cartId}`, body);
  }
  addAllToShoppingCartById(cartId, body): Observable<any> {
    return this.http.put(`${Constants.baseURL}/accounts/account/customer/shoppingCarts/${cartId}/skus`, body);
  }
  updateShoppingCartSkusById(cartId, skuId, body): Observable<any> {
    return this.http.put(`${Constants.baseURL}/accounts/account/customer/shoppingCarts/${cartId}/skus/${skuId}`, body);
  }
  // “Remove” link:
  deleteShoppingCartSkusById(cartId, skuId): Observable<any> {
    return this.http.delete(`${Constants.baseURL}/accounts/account/customer/shoppingCarts/${cartId}/skus/${skuId}`);
  }
  deleteShoppingCartSkusByIdWithoutAccount(cartId, skuId): Observable<any> {
    return this.http.delete(`${Constants.baseURL}/shoppingCarts/${cartId}/skus/${skuId}`);
  }
  getSearchSpringTrending(seed: string): Observable<any> {
    const seedUrl = seed.replace(/,/g, '&seed=');
    // return this.http.get(`${Constants.searchSpringURL}/recommend/trending.json?siteId=${Constants.searchSpringSiteId}&limit=${Constants.searchSpringResultsLimit}`);
    return this.http.get(`${Constants.searchSpringURL}/recommend?siteId=${Constants.searchSpringSiteId}&seed=${seedUrl}&limit=${Constants.searchSpringResultsLimit}`);
  }
}
