import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { PasswordVerificationComponent } from './password-verification/password-verification.component';
import { RouteGuard } from '../shared/guards/route.guard';

const routes: Routes = [
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Create Account - Jamestown Distributors',
      description: 'Register Description'
    },
    canActivate: [RouteGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login',
      description: 'Login Description'
    },
    canActivate: [RouteGuard]
  },
  {
    path: 'forgotpassword',
    component: ForgotpasswordComponent,
    data: {
      title: 'Forgot Password',
      description: 'Forgot Password Description'
    },
    canActivate: [RouteGuard]
  },
  {
    path: 'forgotpassword/verification',
    component: PasswordVerificationComponent,
    data: {
      title: 'Forgot Password Verification',
      description: 'Forgot Password Verification Description'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
