/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-not-found.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/image-lazy-load.directive";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./page-not-found.component";
import * as i6 from "@nguniversal/express-engine/tokens";
import * as i7 from "../../services/title-metadata.service";
var styles_PageNotFoundComponent = [i0.styles];
var RenderType_PageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "w-5/6 xl:w-3/4 mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "container container-padding mx-auto py-3 bg-grey-lighter my-12 pt-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "flex justify-center items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "img", [["appImageLazyLoad", ""], ["loading", "lazy"]], [[1, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; if (("error" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).loadImageOnError() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 4210688, null, 0, i2.ImageLazyLoadDirective, [i1.ElementRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "fa-4x md:fa-5x ml-4 font-black"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["410"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [["class", "text-3xl md:text-4xl mb-2 mt-3 md:mt-2 uppercase font-black"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Page Not Found "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The page you are looking for doesn\u2019t exist or has been moved. "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["class", "button button--fill md:w-1/2 xl:w-1/3 block mt-8 mb-8 md:mb-16 mx-auto"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵted(-1, null, ["Back to Home Page"]))], function (_ck, _v) { var currVal_1 = "./../../../../assets/img/error/error-icon.png"; _ck(_v, 5, 0, currVal_1); var currVal_4 = _ck(_v, 14, 0, "/home"); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).srcAttr; _ck(_v, 4, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 13).target; var currVal_3 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_2, currVal_3); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i5.PageNotFoundComponent, [i1.PLATFORM_ID, [2, i6.RESPONSE], i7.TitleMetadataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i5.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
