import * as i0 from "@angular/core";
var AppInitializerService = /** @class */ (function () {
    function AppInitializerService() {
    }
    AppInitializerService.prototype.Init = function () {
        return new Promise(function (resolve, reject) {
            //// do your initialisation stuff here
            resolve();
        });
    };
    AppInitializerService.ngInjectableDef = i0.defineInjectable({ factory: function AppInitializerService_Factory() { return new AppInitializerService(); }, token: AppInitializerService, providedIn: "root" });
    return AppInitializerService;
}());
export { AppInitializerService };
