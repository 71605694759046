import { RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Constants } from '../constants';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var SearchSpringScriptsService = /** @class */ (function () {
    function SearchSpringScriptsService(rendererFactory, document) {
        this.rendererFactory = rendererFactory;
        this.document = document;
        this.searchSpringPageLoaded = new BehaviorSubject('');
    }
    SearchSpringScriptsService.prototype.addViewItemScript = function (productDetails) {
        var text = "try {\n            var product_code = \"" + productDetails.id + "\";\n            IntelliSuggest.init({siteId:'" + Constants.searchSpringSiteId + "', context:'Product/' + product_code, seed:product_code});\n            IntelliSuggest.viewItem({sku:product_code});\n        } catch(err) {}";
        this.addScript(text);
    };
    SearchSpringScriptsService.prototype.addCartTracking = function (skus) {
        if (skus && skus.length > 0) {
            var text_1 = "try{\n              IntelliSuggest.init({siteId:'" + Constants.searchSpringSiteId + "', context:'Basket', seed:[";
            skus.forEach(function (sku) {
                text_1 += "'" + sku.product.id + "',";
            });
            text_1 += "]});";
            // Loop through products in cart
            skus.forEach(function (sku) {
                text_1 += "IntelliSuggest.haveItem({ sku: \"" + sku.product.id + "\", qty: \"" + sku.quantity + "\", price: \"" + sku.sellPrice + "\" });";
            });
            text_1 += "IntelliSuggest.inBasket({});\n          } catch(err) { }";
            this.addScript(text_1);
        }
    };
    SearchSpringScriptsService.prototype.addOrderTracking = function (orderDetails) {
        var text = "try{\n      IntelliSuggest.init({siteId:'" + Constants.searchSpringSiteId + "'});";
        // Loop through products in cart
        if (orderDetails && orderDetails.skus && orderDetails.skus.length > 0) {
            orderDetails.skus.forEach(function (sku) {
                text += "IntelliSuggest.haveItem({ sku: \"" + sku.product.id + "\", qty: \"" + sku.quantity + "\", price: \"" + sku.sellPrice + "\" });";
            });
        }
        text += "IntelliSuggest.inSale({\n              orderId:\"" + orderDetails.id + "\", // The Order ID (optional)\n              total:\"" + (orderDetails.orderTotal ? orderDetails.orderTotal.toFixed(2) : 0) + "\", // The total order amount (optional)\n              city:\"" + orderDetails.shippingAddress.city + "\", // Customer\"s shipping address City (optional)\n              state:\"" + orderDetails.shippingAddress.state + "\", // Customer\"s shipping address State (optional)\n              country:\"" + Constants.countryUS + "\" // Customer\"s shipping address 2-letter Country Code (optional)\n          });\n        } catch(err) { }";
        this.addScript(text);
    };
    SearchSpringScriptsService.prototype.addIntellisuggestProductTrackClick = function (element, data, signature) {
        var escapeFn = encodeURIComponent || escape;
        if (document.images) {
            var apiUrl = void 0;
            if ('https:' === location.protocol) {
                apiUrl = Constants.searchSpringURL;
            }
            else {
                apiUrl = Constants.searchSpringURL;
            }
            var imgTag = new Image();
            imgTag.src = apiUrl + '/track/track.json?d=' + data + '&s=' + signature + '&u=' + escapeFn(element.href) + '&r=' + escapeFn(document.referrer);
        }
    };
    SearchSpringScriptsService.prototype.addScript = function (scriptText) {
        this.removeScript();
        // Add the listener and script
        var head = this.document.head;
        if (head === null) {
            throw new Error('<head> not found within DOCUMENT.');
        }
        var renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });
        var script = renderer.createElement('script');
        script.text = scriptText;
        script.id = 'ssIntellisuggestTrackingScript';
        renderer.appendChild(head, script);
    };
    SearchSpringScriptsService.prototype.removeScript = function () {
        try {
            var viewItemScript = this.document.getElementById('ssIntellisuggestTrackingScript');
            if (viewItemScript) {
                viewItemScript.parentNode.removeChild(viewItemScript);
            }
        }
        catch (e) {
            console.error('Error within script removing : ', e);
        }
    };
    SearchSpringScriptsService.ngInjectableDef = i0.defineInjectable({ factory: function SearchSpringScriptsService_Factory() { return new SearchSpringScriptsService(i0.inject(i0.RendererFactory2), i0.inject(i1.DOCUMENT)); }, token: SearchSpringScriptsService, providedIn: "root" });
    return SearchSpringScriptsService;
}());
export { SearchSpringScriptsService };
