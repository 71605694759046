import { Injectable, InjectionToken, FactoryProvider } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../constants';

export const WINDOW = new InjectionToken<Window>('window');
const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

export const WINDOW_PROVIDERS = [
  windowProvider
];

@Injectable({
  providedIn: 'root'
})
export class GeneralOperationsService {

  InterceptorSkipLoader = 'X-Skip-Loader';
  constructor(private http: HttpClient) { }

  capitalLetter(str: string): string {
    const stringArray = str.split(' ');
    for (let i = 0, x = stringArray.length; i < x; i++) {
      stringArray[i] = stringArray[i][0].toUpperCase() + stringArray[i].substr(1);
    }
    return stringArray.join(' ');
  }

  getToasterNotifications(url: string): Observable<any> {

    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    return this.http.get(`${Constants.baseURL}/toasters${url}`, { headers});
  }
}
