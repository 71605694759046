import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { LoginService } from '../login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { Constants } from '../../shared/constants';
import { Location } from '@angular/common';

@Component({
  selector: 'app-password-verification',
  templateUrl: './password-verification.component.html',
  styleUrls: ['./password-verification.component.css']
})
export class PasswordVerificationComponent implements OnInit {
  returnUrl: string;
  isUpdateOldPassword: boolean = false;
  forgotPasswordForm = this.fb.group({
    Username: ['', [Validators.required, Validators.email, Validators.pattern(Constants.emailPattern)]],
    OldPassword: ['', Validators.required],
    Password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(98), Validators.pattern(Constants.passwordPattern)]],
    ConfirmPassword: ['', Validators.required]
  });
  verificationCodeForm = this.fb.group({
    Username: ['', [Validators.required, Validators.email, Validators.pattern(Constants.emailPattern)]],
    VerificationCode: ['', Validators.required],
    Password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(98), Validators.pattern(Constants.passwordPattern)]],
    ConfirmPassword: ['', Validators.required]
  });
  constructor(
    private fb: FormBuilder,
    private location: Location,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params && params.email) {
        this.forgotPasswordForm.patchValue({ Username: params.email });
        this.verificationCodeForm.patchValue({ Username: params.email });
        this.returnUrl = params.returnUrl;
      }
      if (params && params.isUpdate) {
        this.isUpdateOldPassword = true;
      }
    });
  }

  updateOldPassword(): void {
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.loginService.updateCognitoPassword(this.forgotPasswordForm.getRawValue()).then(
      response => {
        this.notificationService.showInfo('Password updated successfully.');
        // https://jamestowndistributors.atlassian.net/browse/JDWA4-583
        // , {queryParams: { showLoginMessage: 'true' } }
        this.router.navigate(['useraccount/accountinfo']);
      }, reject => {
        this.notificationService.showError(reject.message);
      }
    ).catch((err) => {
      this.notificationService.showError(err);
    });
  }

updatePassword(): void {
  if (this.verificationCodeForm.invalid) {
    return;
  }
  this.verificationCodeForm.controls.VerificationCode.setValue(this.verificationCodeForm.controls.VerificationCode.value.trim());
  this.loginService.updatePassword(this.verificationCodeForm.getRawValue()).then(
    () => {
      this.notificationService.showInfo('Password set successfully.');
      if (this.returnUrl) {
        this.loginService.logout();
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.router.navigate(['']);
      }
    }, reject => {
      this.notificationService.showError(reject.message);
    });
}

  navigateBack(): void {
    this.location.back();
  }
}
