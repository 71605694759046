import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { PasswordVerificationComponent } from './password-verification/password-verification.component';
import { RouteGuard } from '../shared/guards/route.guard';
var ɵ0 = {
    title: 'Create Account - Jamestown Distributors',
    description: 'Register Description'
}, ɵ1 = {
    title: 'Login',
    description: 'Login Description'
}, ɵ2 = {
    title: 'Forgot Password',
    description: 'Forgot Password Description'
}, ɵ3 = {
    title: 'Forgot Password Verification',
    description: 'Forgot Password Verification Description'
};
var routes = [
    {
        path: 'register',
        component: RegisterComponent,
        data: ɵ0,
        canActivate: [RouteGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ1,
        canActivate: [RouteGuard]
    },
    {
        path: 'forgotpassword',
        component: ForgotpasswordComponent,
        data: ɵ2,
        canActivate: [RouteGuard]
    },
    {
        path: 'forgotpassword/verification',
        component: PasswordVerificationComponent,
        data: ɵ3
    }
];
var LoginRoutingModule = /** @class */ (function () {
    function LoginRoutingModule() {
    }
    return LoginRoutingModule;
}());
export { LoginRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
