import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {

  constructor() { }

  Init() {

    return new Promise<void>((resolve, reject) => {
      //// do your initialisation stuff here
      resolve();
    });
  }
}
