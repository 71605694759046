import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../shared/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UseraccountService = /** @class */ (function () {
    function UseraccountService(http) {
        this.http = http;
    }
    // Order List Api Start
    // Get Orderlist Resources of logged in user from Jamestown GET Request
    UseraccountService.prototype.getOrderlists = function (paginationQuery) {
        return this.http.get(Constants.baseURL + "/accounts/account/customer/orders/skus?" + paginationQuery);
    };
    // Create Customer account of logged in user from Jamestown POST Request
    UseraccountService.prototype.createCustomer = function (body) {
        return this.http.post(Constants.baseURL + "/accounts/account/customer", body);
    };
    // Wishlist APIs Start
    // Get Wishlist Resources of logged in user from Jamestown GET Request
    UseraccountService.prototype.getWishlists = function (paginationQuery) {
        return this.http.get(Constants.baseURL + "/accounts/account/customer/wishlists?" + paginationQuery);
    };
    // Get Wishlist Details of logged in user from Jamestown POST Request
    UseraccountService.prototype.getWishlistsSKUS = function (wishlistId, paginationQuery, sortingQuery) {
        return this.http.get(Constants.baseURL + "/accounts/account/customer/wishlists/" + wishlistId + "/skus?" + paginationQuery + "&" + sortingQuery);
    };
    // Get Wishlist Details of logged in user from Jamestown POST Request
    UseraccountService.prototype.getWishlistsSKUSWithHashId = function (wishlistHashId) {
        return this.http.get(Constants.baseURL + "/wishlists/" + wishlistHashId);
    };
    // Get orderlist Details of logged in user from Jamestown POST Request
    UseraccountService.prototype.getOrderlistsSKUS = function (paginationQuery, sortingQuery) {
        return this.http.get(Constants.baseURL + "/accounts/account/customer/orders/skus?" + paginationQuery + "&" + sortingQuery);
    };
    // Get Wishlist Details of logged in user from Jamestown POST Request
    UseraccountService.prototype.getWishlistsResources = function (wishlistId) {
        return this.http.get(Constants.baseURL + "/accounts/account/customer/wishlists/" + wishlistId);
    };
    // Create Wishlist Resources of logged in user from Jamestown POST Request
    UseraccountService.prototype.createWishlist = function (body) {
        return this.http.post(Constants.baseURL + "/accounts/account/customer/wishlists/", body);
    };
    // Updates Wishlist Resources of logged in user from Jamestown POST Request
    UseraccountService.prototype.updateWishlist = function (body) {
        return this.http.put(Constants.baseURL + "/accounts/account/customer/wishlists/" + body.id, body);
    };
    // Update Wishlist SKU Resource of logged in user from Jamestown POST Request
    UseraccountService.prototype.updateWishlistSKU = function (body, wishlistId) {
        return this.http.put(Constants.baseURL + "/accounts/account/customer/wishlists/" + wishlistId + "/skus/" + body.id, body);
    };
    // Deletes a SKU resource from Wishlist using Jamestown DELETE Request
    UseraccountService.prototype.deleteWishlistSKUItem = function (wishlistId, removeItemId) {
        return this.http.delete(Constants.baseURL + "/accounts/account/customer/wishlists/" + wishlistId + "/skus/" + removeItemId);
    };
    // Deletes a Wishlist using Jamestown DELETE Request
    UseraccountService.prototype.deleteWishlist = function (wishlistId) {
        return this.http.delete(Constants.baseURL + "/accounts/account/customer/wishlists/" + wishlistId);
    };
    // Get Wishlist Details of logged in user from Jamestown POST Request
    UseraccountService.prototype.getSharedWishlistsPeople = function (wishlistId) {
        return this.http.get(Constants.baseURL + "/accounts/account/customer/wishlists/" + wishlistId + "/sharedWishlists");
    };
    // Deletes a Shared Wishlist person using Jamestown DELETE Request
    UseraccountService.prototype.deleteSharedWishlistsPerson = function (wishlistId, personWishlistHashId) {
        return this.http.delete("\n    " + Constants.baseURL + "/accounts/account/customer/wishlists/" + wishlistId + "/sharedWishlists/" + personWishlistHashId + "\n    ");
    };
    // Deletes a Shared Wishlist person using Jamestown DELETE Request
    UseraccountService.prototype.addSharedWishlistsPerson = function (wishlistId, data) {
        return this.http.post(Constants.baseURL + "/accounts/account/customer/wishlists/" + wishlistId + "/sharedWishlists/", data);
    };
    // Wishlist APIs End
    // Address APIs Start
    UseraccountService.prototype.getCustomerAddresses = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.get(Constants.baseURL + "/accounts/account/customer/shippingAddresses", { params: params });
    };
    UseraccountService.prototype.getDefaultCustomerAddresses = function () {
        return this.http.get(Constants.baseURL + "/accounts/account/customer/shippingAddresses/default");
    };
    UseraccountService.prototype.addCustomerAddresses = function (address) {
        return this.http.post(Constants.baseURL + "/accounts/account/customer/shippingAddresses/", address);
    };
    UseraccountService.prototype.updateCustomerAddresses = function (address) {
        return this.http.put(Constants.baseURL + "/accounts/account/customer/shippingAddresses/" + address.id, address);
    };
    UseraccountService.prototype.deleteCustomerAddresses = function (address) {
        return this.http.delete(Constants.baseURL + "/accounts/account/customer/shippingAddresses/" + address.id);
    };
    // Address APIs End
    // Payments APIs Start
    UseraccountService.prototype.getDefaultPayment = function () {
        return this.http.get(Constants.baseURL + "/accounts/account/customer/paymentMethods/default");
    };
    UseraccountService.prototype.getAllPayment = function (sortParam) {
        if (sortParam === void 0) { sortParam = ''; }
        return this.http.get(Constants.baseURL + "/accounts/account/customer/paymentMethods" + sortParam);
    };
    UseraccountService.prototype.addPayments = function (payment) {
        return this.http.post(Constants.baseURL + "/accounts/account/customer/paymentMethods/", payment);
    };
    UseraccountService.prototype.updatePayment = function (payment) {
        return this.http.put(Constants.baseURL + "/accounts/account/customer/paymentMethods/" + payment.id, payment);
    };
    UseraccountService.prototype.removePayments = function (paymentId) {
        return this.http.delete(Constants.baseURL + "/accounts/account/customer/paymentMethods/" + paymentId);
    };
    // Set card type
    UseraccountService.prototype.setCardType = function (cardNumber) {
        cardNumber = cardNumber.replace(/ /g, '').trim();
        if (Constants.amexpRegEx.test(cardNumber)) {
            return Constants.americanExpress.key;
        }
        if (Constants.visaRegEx.test(cardNumber)) {
            return Constants.visa.key;
        }
        if (Constants.mastercardRegEx.test(cardNumber)) {
            return Constants.masterCard.key;
        }
        if (Constants.discovRegEx.test(cardNumber)) {
            return Constants.discoverCard.key;
        }
    };
    UseraccountService.prototype.validateCreditCardNumber = function (cardNumber) {
        var isValid = false;
        var ccNum = cardNumber.value.replace(/ /g, '').trim();
        if (Constants.visaRegEx.test(ccNum)) {
            isValid = true;
        }
        else if (Constants.mastercardRegEx.test(ccNum)) {
            isValid = true;
        }
        else if (Constants.amexpRegEx.test(ccNum)) {
            isValid = true;
        }
        else if (Constants.discovRegEx.test(ccNum)) {
            isValid = true;
        }
        if (!isValid) {
            cardNumber.setErrors({ ccNumber: true });
        }
        return isValid;
    };
    UseraccountService.ngInjectableDef = i0.defineInjectable({ factory: function UseraccountService_Factory() { return new UseraccountService(i0.inject(i1.HttpClient)); }, token: UseraccountService, providedIn: "root" });
    return UseraccountService;
}());
export { UseraccountService };
