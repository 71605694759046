import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Constants } from '../shared/constants';
import { StorageService } from '../shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})

export class ProductService {
  InterceptorSkipLoader = 'X-Skip-Loader';
  constructor(private http: HttpClient, private storageService: StorageService) { }

  // Get Categories from Jamestown GET Request
  getCategories(pagination: any, category: string, subCategory: string): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    let url = `${Constants.baseURL}/categories/${category}`;
    if (subCategory) {
      url += `/subcategories/${subCategory}`;
    }
    url += `?page=${pagination.pageNumber}&size=${pagination.pageSize} `;
    return this.http.get(url, { headers });
  }
  // searchSpring POST Request
  // Headers added through HTTP Interceptor in the file shared/services/http-error.interceptor.service.ts
  getSearchSpringResponse(url: string): Observable<any> {
    return this.http.get(`${Constants.searchSpringURL}/search/search.json?siteId=${Constants.searchSpringSiteId}${url}&resultsFormat=native`);
  }

  getAutoCompleteSearchSpringResponse(url: string): Observable<any> {
    return this.http.get(`${Constants.autocompleteSearchSpringURL}/search/autocomplete.json?siteId=${Constants.searchSpringSiteId}${url}&resultsFormat=native`);
  }

  // Get Product Details from Jamestown GET Request
  getProductDetail(id: number): Observable<any> {
    return this.http.get(`${Constants.baseURL}/products/${id}`);
  }

  // Get Product SKU from Jamestown GET Request
  getProductSKU(productId: number, skuId: number): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    return this.http.get(`${Constants.baseURL}/products/${productId}/skus/${skuId}`, { headers });
  }

  // Get Recommended Product List searchSpring
  getRecommendedProducts(url: string): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    return this.http.get(`${Constants.searchSpringURL}/recommend?siteId=${Constants.searchSpringSiteId}${url}`, { headers });
  }

  // Get Frequently Purchased Together searchSpring
  getFrequentlyPurchasedProducts(url: string): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    return this.http.get(`${Constants.searchSpringURL}/recommend/best-selling?siteId=${Constants.searchSpringSiteId}${url}`, { headers });
  }

  // Get Recently Viewed Products searchSpring
  getRecentlyViewedProducts(): Observable<any> {
    return of(this.storageService.getItem('recentlyViewedProducts'));
  }

  // Get Global Search results searchSpring
  getIntelliSuggestProducts(query: string, termCount: number, productCount: number): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    return this.http.get(`${Constants.searchSpringIntelliSuggestURL}?siteId=${Constants.searchSpringSiteId}&query=${query}&termCount=${termCount}&productCount=${productCount}`, { headers });
  }

  // Get Recently Viewed Products searchSpring
  getProductPowerReviews(url: string): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    return this.http.get(url, { headers });
  }

  // Get Recently Viewed Products searchSpring
  postPowerReviewsVote(body: any): Observable<any> {
    const headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
    return this.http.post(Constants.powerReview.writeVoteAPI, body, { headers });
  }
}
