import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    @Inject(PLATFORM_ID) private platform: any
  ) { }

  // Gets item from storage
  getItem(key: string): any {
    let value = localStorage.getItem(key);
    try {
      value = JSON.parse(value);
    } catch (e) {
      if (e === 'Unexpected token e in JSON at position 0') {
        value = localStorage.getItem(key);
      }
    }
    return value;
  }

  // Sets item from storage
  setItem(key: string, value: any): void {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  // Remove item from storage
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // Clear all items
  clear(): void {
    localStorage.clear();
  }

  // Gets item from session storage
  sessionGetItem(key: string): any {
    if (isPlatformBrowser(this.platform)) {
      let value = sessionStorage.getItem(key);
      try {
        value = JSON.parse(value);
      } catch (e) {
        if (e === 'Unexpected token e in JSON at position 0') {
          value = sessionStorage.getItem(key);
        }
      }
      return value;
    }
  }

  // Sets item from storage
  sessionSetItem(key: string, value: any): void {
    if (isPlatformBrowser(this.platform)) {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }
      sessionStorage.setItem(key, value);
    }
  }

  // Remove item from storage
  sessionRemoveItem(key: string): void {
    if (isPlatformBrowser(this.platform)) {
      sessionStorage.removeItem(key);
    }
  }

  // Clear all items
  sessionClear(): void {
    if (isPlatformBrowser(this.platform)) {
      sessionStorage.clear();
    }
  }

}
