/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loader.component";
import * as i4 from "../../services/loader.service";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
function View_LoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50"], ["style", "z-index:10000 !important"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "text-green-500 opacity-75 top-1/2 my-0 block absolute w-0 h-0"], ["style", "margin:0;position:absolute;left:50%;margin-right:-50%;transform:translate(-50%, -50%);top:34%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-circle-notch fa-spin fa-5x"]], null, null, null, null, null))], null, null); }
function View_LoaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50"], ["style", "z-index:10000 !important"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "text-green-500 opacity-75 top-1/2 my-0 block absolute w-0 h-0"], ["style", "margin:0;position:absolute;left:50%;margin-right:-50%;transform:translate(-50%, -50%);top:34%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-circle-notch fa-spin fa-5x"]], null, null, null, null, null))], null, null); }
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_LoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LoaderComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.showLoader)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.showRouteLoader)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoaderComponent, [i4.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i3.LoaderComponent, View_LoaderComponent_Host_0, {}, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
