import { Validator, AbstractControl } from '@angular/forms';
var MatchValueDirective = /** @class */ (function () {
    function MatchValueDirective(validateEqual, reverse) {
        this.validateEqual = validateEqual;
        this.reverse = reverse;
    }
    Object.defineProperty(MatchValueDirective.prototype, "isReverse", {
        get: function () {
            if (!this.reverse) {
                return false;
            }
            return this.reverse === 'true' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    MatchValueDirective.prototype.validate = function (control) {
        // self value
        var value = control.value;
        // control vlaue
        var element = control.root.get(this.validateEqual);
        // value not equal
        if (element && value !== element.value && !this.isReverse) {
            return {
                validateEqual: false
            };
        }
        // value equal and reverse
        if (element && value === element.value && this.isReverse) {
            if (element.errors) {
                delete element.errors['validateEqual'];
            }
            if (element.errors && !Object.keys(element.errors).length) {
                element.setErrors(null);
            }
        }
        // value not equal and reverse
        if (element && value !== element.value && this.isReverse) {
            element.setErrors({ validateEqual: false });
        }
        return null;
    };
    return MatchValueDirective;
}());
export { MatchValueDirective };
