import { Injectable } from '@angular/core';
import { ToastrService, ToastPackage } from 'ngx-toastr';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notify: Subject<boolean> = new Subject<boolean>();
  toastTimeout:any;

  constructor(private toastr: ToastrService) { }

  showSuccess(message:any, title = 'Success'): void {
    const successToaster = this.toastr.success(this.roleAlertTitleMessage(title, message), title);
    this.anchorTagAriaHiddenAndPlayPause(successToaster);
  }

  showError(message:any, title = 'Error'): void {
    const successToaster = this.toastr.error(this.roleAlertTitleMessage(title, message), title);
    this.anchorTagAriaHiddenAndPlayPause(successToaster);
  }

  showInfo(message:any, title = 'Info'): void {
    const successToaster = this.toastr.info(this.roleAlertTitleMessage(title, message), title);
    this.anchorTagAriaHiddenAndPlayPause(successToaster);
  }

  showWarning(message:any, title = 'Warning'): void {
    const successToaster = this.toastr.warning(this.roleAlertTitleMessage(title, message), title);
    this.anchorTagAriaHiddenAndPlayPause(successToaster);
  }
  
  hide() {
    this.toastr.clear()
  }

  notifyLogin(event:any): void {
    this.notify.next(true);
  }

  roleAlertTitleMessage(title:any, message:any) {
    return `<div class="ngx-toastr-title-with-message" tabindex="0" role="alert"><span>${title}</span> ${message} </div> <div  class="ngx-toastr-play-pause"><i class="fa fa-pause play-pause-icon" aria-hidden="true"></i></div>`
  }

  anchorTagAriaHiddenAndPlayPause(successToaster:any) {
    setTimeout(() => {
      const element:any = document.querySelector('.ngx-toastr-title-with-message')
      if(element) {
        element.querySelector('a').setAttribute('aria-hidden', 'true');
      }
    }, 100)

    setTimeout(() => {
      this.toastTimeout = setTimeout(() => {
        this.toastr.remove(successToaster.toastId)
      }, 5000)
      const playPauseIcon:any = document.querySelector('.ngx-toastr-play-pause .play-pause-icon');
      if(playPauseIcon) {
        playPauseIcon.addEventListener('click', () => {
          if (playPauseIcon.classList.contains('fa-pause')) {
            clearTimeout(this.toastTimeout);
            playPauseIcon.classList.replace('fa-pause', 'fa-play');
          } else if (playPauseIcon.classList.contains('fa-play')) {
            this.toastTimeout = setTimeout(() => {
              this.toastr.remove(successToaster.toastId)
            }, 5000)
            playPauseIcon.classList.replace('fa-play', 'fa-pause');
          }
        })
      }
    })
  }
}
