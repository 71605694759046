import { RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Constants } from '../constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var BingAnalyticsService = /** @class */ (function () {
    function BingAnalyticsService(rendererFactory, document) {
        this.rendererFactory = rendererFactory;
        this.document = document;
    }
    BingAnalyticsService.prototype.pagePathTracker = function (pageURL) {
        var pageTracker = "window.uetq.push('event', 'page_view', { 'page_path': '" + pageURL + "' });";
        this.constructUETBingScript(pageTracker);
    };
    BingAnalyticsService.prototype.orderTracker = function (orderDetails) {
        var orderTracker = "window.uetq.push('event', '', {\n      'revenue_value': '" + orderDetails.orderTotal + "',\n      'currency': '" + Constants.currencyUSD + "'\n    });";
        this.constructUETBingScript(orderTracker);
    };
    BingAnalyticsService.prototype.constructUETBingScript = function (customTrackerCode) {
        var text = "(function(w,d,t,r,u){\n          var f,n,i;w[u]=w[u]||[],f=function(){\n          var o={\n            ti:\"5213831\"\n          };\n          o.q=w[u],w[u]=new UET(o),w[u].push(\"pageLoad\")\n        },\n        n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){\n          var s=this.readyState;s&&s!==\"loaded\"&&s!==\"complete\"||(f(),\n          n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)\n        })(window,document,\"script\",\"//bat.bing.com/bat.js\",\"uetq\");\n\n        window.uetq = window.uetq || [];\n        " + customTrackerCode;
        this.addScript(text);
    };
    BingAnalyticsService.prototype.addScript = function (scriptText) {
        this.removeScript();
        // Add the listener and script
        var head = this.document.head;
        if (head === null) {
            throw new Error('<head> not found within DOCUMENT.');
        }
        var renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });
        var script = renderer.createElement('script');
        script.text = scriptText;
        script.id = 'uetConversionTracker';
        renderer.appendChild(head, script);
    };
    BingAnalyticsService.prototype.removeScript = function () {
        try {
            var viewItemScript = this.document.getElementById('uetConversionTracker');
            if (viewItemScript) {
                viewItemScript.parentNode.removeChild(viewItemScript);
            }
        }
        catch (e) {
            console.error('Error within script removing : ', e);
        }
    };
    BingAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function BingAnalyticsService_Factory() { return new BingAnalyticsService(i0.inject(i0.RendererFactory2), i0.inject(i1.DOCUMENT)); }, token: BingAnalyticsService, providedIn: "root" });
    return BingAnalyticsService;
}());
export { BingAnalyticsService };
