<div class="login-wrapper">
    <a type="button" id="first-tab-index" tabindex="1" ></a>
    <div class="page-header pt-8 pb-10 lg:py-12">

        <div class="container container-padding mx-auto flex justify-center">
            <h1 class="text-center uppercase text-5xl font-display page-header__title lines-centered-red font-medium">
                <div tabindex="0">Forgot Password?</div>
            </h1>
        </div>

    </div>

    <div class="container container-padding mx-auto py-1 pb-20">

        <form [formGroup]="forgotPasswordForm" class="max-w-md mx-auto bg-white rounded-lg py-6 sm:px-10">
            <!-- Enter Email ID to send the Verfication Code -->
            <div class="text-center text-sm text-black pt-3 pb-6 max-w-xs mx-auto" tabindex="0">Enter your email address below and we’ll send a password reset code</div>

            <div class="mb-3 input-group">
                <label class="input-label">Email</label>
                <input type="email" class="input w-full" aria-describedby="email-required-errors" placeholder="Email" formControlName="Username" autocomplete="email" [ngClass]="{'input--error': forgotPasswordForm.controls.Username.invalid && forgotPasswordForm.controls.Username.touched}">
                <div class="input-faux-label">Email</div>
                <div  id="email-required-errors" *ngIf="forgotPasswordForm.controls.Username.errors">
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.Username.errors.required && forgotPasswordForm.controls.Username.touched">
                        Email is required.
                    </span>
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.Username.errors.email || (forgotPasswordForm.controls.Username.errors.pattern && forgotPasswordForm.controls.Username.touched)">
                        Please enter valid email.
                    </span>
                </div>
            </div>

            <div class="mt-10">
                <button class="button button--border block mb-3 w-full md:w-auto mx-auto focus" (click)="forgotPassword()">Send Reset Code</button>
            </div>
            <p class="text-center mb-6"><a href="javascript:void(0)" (click)="navigateBack()" class="no-underline">Cancel</a></p>
        </form>

    </div>
    <!--/container-->

</div>
<!--/checkout-wrapper-->