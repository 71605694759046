import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceElement'
})
export class ReplaceElementPipe implements PipeTransform {
  constructor() {

  }

  transform(value: any, args?: any): any {
    return this.stylize(value);
  }

  private stylize(text: string): string {
    if (text && text.length > 0) {
      text = text.replace(/<em>/g, '<strong class="whitelisted">');
      text = text.replace(/<\/em>/g, '</strong>');
      return text;
    } else {
      return text;
    }
  }
}
