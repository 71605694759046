<div class="login-wrapper">
    <div class="page-header pt-8 pb-10 lg:py-12">
        <div class="container container-padding mx-auto">
            <div class="text-center uppercase text-5xl font-display page-header__title lines-centered-red">Update Password
            </div>
        </div>
    </div>
    <div class="container container-padding mx-auto py-1 pb-20">
        <form [formGroup]="forgotPasswordForm" *ngIf="isUpdateOldPassword" class="max-w-md mx-auto bg-white rounded-lg py-6 sm:px-10">
            <div class="mb-3 input-group">
                <label class="input-label">Old Password</label>
                <input type="password" class="input w-full" placeholder="Old Password" formControlName="OldPassword" validateEqual="ConfirmPassword" reverse="true" [ngClass]="{'input--error': forgotPasswordForm.controls.OldPassword.invalid && forgotPasswordForm.controls.OldPassword.touched}">
                <div class="input-faux-label">Old Password</div>
                <div *ngIf="forgotPasswordForm.controls.OldPassword.errors">
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.OldPassword.errors.required && forgotPasswordForm.controls.OldPassword.touched">
            Old Password
            is
            required.
          </span>
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.OldPassword.errors.minlength && forgotPasswordForm.controls.OldPassword.touched">
            Minimum
            8
            characters required.
          </span>
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.OldPassword.errors.pattern && forgotPasswordForm.controls.OldPassword.touched">
            Old Password
            should contain uppercase, lowercase, number and special characters.
          </span>
                </div>
            </div>

            <div class="mb-3 input-group">
                <label class="input-label">Password</label>
                <input type="password" class="input w-full" placeholder="Password" formControlName="Password" autocomplete="new-password" validateEqual="ConfirmPassword" reverse="true" [ngClass]="{'input--error': forgotPasswordForm.controls.Password.invalid && forgotPasswordForm.controls.Password.touched}">
                <div class="input-faux-label">Password</div>
                <div *ngIf="forgotPasswordForm.controls.Password.errors">
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.Password.errors.required && forgotPasswordForm.controls.Password.touched">
            Password
            is
            required.
          </span>
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.Password.errors.minlength && forgotPasswordForm.controls.Password.touched">
            Minimum
            8
            characters required.
          </span>
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.Password.errors.maxlength && forgotPasswordForm.controls.Password.touched">
Maximum 98 characters are allowed.
</span>
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.Password.errors.pattern && forgotPasswordForm.controls.Password.touched">
            Password
            should contain uppercase, lowercase, number and special characters.
          </span>
                </div>
            </div>

            <div class="mb-3 input-group">
                <label class="input-label">Confirm Password</label>
                <input type="password" class="input w-full" placeholder="Confirm Password" formControlName="ConfirmPassword" autocomplete="new-password" validateEqual="Password" [ngClass]="{'input--error': forgotPasswordForm.controls.ConfirmPassword.invalid && forgotPasswordForm.controls.ConfirmPassword.touched}">
                <div class="input-faux-label">Confirm Password</div>
                <div *ngIf="forgotPasswordForm.controls.ConfirmPassword.errors">
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.ConfirmPassword.errors.required && forgotPasswordForm.controls.ConfirmPassword.touched">
            Confirm Password is required.</span>
                    <span class="input-error-message" *ngIf="forgotPasswordForm.controls.ConfirmPassword.errors && forgotPasswordForm.controls.ConfirmPassword.touched">
            Passwords do not match.</span>
                </div>
            </div>
            <div class="mt-4">
                <button class="button button--border block mb-3 w-full md:w-auto mx-auto" [disabled]="forgotPasswordForm.invalid" (click)="updateOldPassword()">Update Password</button>
            </div>
            <div class="text-center text-sm"><a (click)="navigateBack()" class="no-underline">Cancel</a></div>
        </form>
        <form [formGroup]="verificationCodeForm" *ngIf="!isUpdateOldPassword" class="max-w-md mx-auto bg-white rounded-lg py-6 sm:px-10">
            <!-- Verification Code Sent -->
            <div class="text-center text-sm text-black pt-3 pb-3">The verification code has been sent to your inbox.<br> It may take a few moments.</div>

            <div class="mb-3 input-group">
                <label class="input-label">Verification Code</label>
                <input type="text" class="input w-full" placeholder="Verification Code" formControlName="VerificationCode" [ngClass]="{'input--error': verificationCodeForm.controls.VerificationCode.invalid && verificationCodeForm.controls.VerificationCode.touched}">
                <div class="input-faux-label">Verification Code</div>
                <div *ngIf="verificationCodeForm.controls.VerificationCode.errors">
                    <span class="input-error-message" *ngIf="verificationCodeForm.controls.VerificationCode.errors.required && verificationCodeForm.controls.VerificationCode.touched">
            Verification Code is required.
          </span>
                    <span class="input-error-message" *ngIf="verificationCodeForm.controls.VerificationCode.errors.invalid && verificationCodeForm.controls.VerificationCode.touched">
            Verification Code invalid.
          </span>
                </div>
            </div>

            <div class="mb-3 input-group">
                <label class="input-label">Password</label>
                <input type="password" class="input w-full" placeholder="Password" formControlName="Password" autocomplete="new-password" validateEqual="ConfirmPassword" reverse="true" [ngClass]="{'input--error': verificationCodeForm.controls.Password.invalid && verificationCodeForm.controls.Password.touched}">
                <div class="input-faux-label">Password</div>
                <div *ngIf="verificationCodeForm.controls.Password.errors">
                    <span class="input-error-message" *ngIf="verificationCodeForm.controls.Password.errors.required && verificationCodeForm.controls.Password.touched">
            Password
            is
            required.
          </span>
                    <span class="input-error-message" *ngIf="verificationCodeForm.controls.Password.errors.minlength && verificationCodeForm.controls.Password.touched">
            Minimum
            8
            characters required.
          </span>
                    <span class="input-error-message" *ngIf="verificationCodeForm.controls.Password.errors.maxlength && verificationCodeForm.controls.Password.touched">
  Maximum 98 characters are allowed.
</span>
                    <span class="input-error-message" *ngIf="verificationCodeForm.controls.Password.errors.pattern && verificationCodeForm.controls.Password.touched">
            Password
            should contain uppercase, lowercase, number and special characters.
          </span>
                </div>
            </div>

            <div class="mb-3 input-group">
                <label class="input-label">Confirm Password</label>
                <input type="password" class="input w-full" placeholder="Confirm Password" formControlName="ConfirmPassword" autocomplete="new-password" validateEqual="Password" [ngClass]="{'input--error': verificationCodeForm.controls.ConfirmPassword.invalid && verificationCodeForm.controls.ConfirmPassword.touched}">
                <div class="input-faux-label">Confirm Password</div>
                <div *ngIf="verificationCodeForm.controls.ConfirmPassword.errors">
                    <span class="input-error-message" *ngIf="verificationCodeForm.controls.ConfirmPassword.errors.required && verificationCodeForm.controls.ConfirmPassword.touched">
            Confirm Password is required.</span>
                    <span class="input-error-message" *ngIf="verificationCodeForm.controls.ConfirmPassword.errors && verificationCodeForm.controls.ConfirmPassword.touched">
            Passwords do not match.</span>
                </div>
            </div>
            <div class="mt-4">
                <button class="button button--border block mb-3 w-full md:w-auto mx-auto" [disabled]="verificationCodeForm.invalid" (click)="updatePassword()">Update Password</button>
            </div>
            <div class="text-center text-sm"><a (click)="navigateBack()" class="no-underline">Cancel</a></div>
        </form>
    </div>
</div>