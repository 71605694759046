import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  showLoader: Subject<boolean> = this.loaderService.isLoading;
  showRouteLoader: Subject<boolean> = this.loaderService.isRouteLoading;
  
  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
  }

}
