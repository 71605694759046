import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { LoginService } from './../../login/login.service';
import * as i0 from "@angular/core";
import * as i1 from "../../login/login.service";
import * as i2 from "@angular/router";
var RouteGuard = /** @class */ (function () {
    function RouteGuard(loginService, router) {
        this.loginService = loginService;
        this.router = router;
    }
    RouteGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.loginService.user.pipe(take(1), map(function (user) {
            if (!!user) {
                if (next.url && next.url[0].path === 'forgotpassword') {
                    _this.router.navigate(['forgotpassword/verification'], {
                        queryParams: { email: user.email, isUpdate: true, returnUrl: '/useraccount/accountinfo' },
                        queryParamsHandling: 'merge'
                    });
                }
                else if (next.url && (next.url[0].path === 'register' || next.url[0].path === 'login')) {
                    _this.router.navigate(['useraccount/accountinfo']);
                }
                return true;
            }
            else {
                return true;
            }
        }));
    };
    RouteGuard.ngInjectableDef = i0.defineInjectable({ factory: function RouteGuard_Factory() { return new RouteGuard(i0.inject(i1.LoginService), i0.inject(i2.Router)); }, token: RouteGuard, providedIn: "root" });
    return RouteGuard;
}());
export { RouteGuard };
