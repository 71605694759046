import * as tslib_1 from "tslib";
import { RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Constants } from '../constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/platform-browser";
import * as i3 from "@angular/router";
var TitleMetadataService = /** @class */ (function () {
    function TitleMetadataService(dom, titleService, metaService, router, activatedRoute, rendererFactory) {
        this.dom = dom;
        this.titleService = titleService;
        this.metaService = metaService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.rendererFactory = rendererFactory;
    }
    TitleMetadataService.prototype.updateTitle = function (title) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!title) {
                    this.router.events
                        .pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.activatedRoute; }), map(function (route) {
                        while (route.firstChild) {
                            route = route.firstChild;
                        }
                        return route;
                    }), filter(function (route) { return route.outlet === 'primary'; }), mergeMap(function (route) { return route.data; })).subscribe(function (event) {
                        if (event) {
                            _this.titleService.setTitle(event.title);
                        }
                    });
                }
                else {
                    this.titleService.setTitle(title);
                }
                return [2 /*return*/];
            });
        });
    };
    TitleMetadataService.prototype.updateMetaInfo = function (url, author) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.metaService.updateTag({ name: 'og:url', content: url });
                this.metaService.updateTag({ name: 'author', content: author });
                return [2 /*return*/];
            });
        });
    };
    TitleMetadataService.prototype.updateMetaKeywords = function (contentStr) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.metaService.updateTag({ name: 'keyword', content: contentStr });
                return [2 /*return*/];
            });
        });
    };
    TitleMetadataService.prototype.addMetaTags = function (nameStr, contentStr) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.metaService.addTag({ name: nameStr, content: contentStr });
                return [2 /*return*/];
            });
        });
    };
    TitleMetadataService.prototype.updateDescription = function (desc) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!desc) {
                    this.router.events
                        .pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.activatedRoute; }), map(function (route) {
                        while (route.firstChild) {
                            route = route.firstChild;
                        }
                        return route;
                    }), filter(function (route) { return route.outlet === 'primary'; }), mergeMap(function (route) { return route.data; })).subscribe(function (event) {
                        _this.metaService.updateTag({ name: 'description', content: event.description });
                        _this.removeCanonicalLink('canonical');
                    });
                }
                else {
                    this.metaService.updateTag({ name: 'description', content: desc });
                }
                return [2 /*return*/];
            });
        });
    };
    TitleMetadataService.prototype.createCanonicalURL = function (url, paginationInfo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var canURL, baseURL, renderer, link, head, tag;
            return tslib_1.__generator(this, function (_a) {
                canURL = '';
                if (url.indexOf('page=') > 0) {
                    if (url.indexOf('?') > 0) {
                        url = url.split('?')[0];
                        if (paginationInfo && paginationInfo.pageNumber !== 1) {
                            url += "?page=" + paginationInfo.pageNumber;
                        }
                    }
                    else {
                        url = url;
                    }
                }
                else if (url.indexOf('?') > 0) {
                    url = url.split('?')[0];
                }
                else if (url.indexOf('#') > 0) {
                    url = url.split('#')[0];
                }
                baseURL = Constants.domainURL;
                canURL = ("" + baseURL + url).toLowerCase();
                // }
                this.removeCanonicalLink('canonical');
                renderer = this.rendererFactory.createRenderer(this.dom, {
                    id: '-1',
                    encapsulation: ViewEncapsulation.None,
                    styles: [],
                    data: {}
                });
                link = renderer.createElement('link');
                head = this.dom.head;
                tag = { rel: 'canonical', href: canURL };
                // const selector = this._parseSelector(tag);
                if (head === null) {
                    throw new Error('<head> not found within DOCUMENT.');
                }
                Object.keys(tag).forEach(function (prop) {
                    return renderer.setAttribute(link, prop, tag[prop]);
                });
                renderer.appendChild(head, link);
                return [2 /*return*/];
            });
        });
    };
    TitleMetadataService.prototype.createRelURL = function (urlRel, url, pageNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var canURL, baseURL, renderer, link, head, tag;
            return tslib_1.__generator(this, function (_a) {
                this.removeCanonicalLink(urlRel);
                canURL = '';
                if (url.indexOf('page=') > 0) {
                    if (url.indexOf('?') > 0) {
                        url = url.split('?')[0];
                    }
                    else {
                        url = url;
                    }
                }
                if (pageNumber === 0) {
                    return [2 /*return*/, false];
                }
                else {
                    if (pageNumber === 1) {
                        url = url;
                    }
                    else {
                        url += "?page=" + pageNumber;
                    }
                }
                baseURL = Constants.domainURL;
                canURL = ("" + baseURL + url).toLowerCase();
                renderer = this.rendererFactory.createRenderer(this.dom, {
                    id: '-1',
                    encapsulation: ViewEncapsulation.None,
                    styles: [],
                    data: {}
                });
                link = renderer.createElement('link');
                head = this.dom.head;
                tag = { rel: urlRel, href: canURL };
                // const selector = this._parseSelector(tag);
                if (head === null) {
                    throw new Error('<head> not found within DOCUMENT.');
                }
                Object.keys(tag).forEach(function (prop) {
                    return renderer.setAttribute(link, prop, tag[prop]);
                });
                renderer.appendChild(head, link);
                return [2 /*return*/];
            });
        });
    };
    TitleMetadataService.prototype.removeCanonicalLink = function (urlRel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var renderer, canonical, head;
            return tslib_1.__generator(this, function (_a) {
                try {
                    renderer = this.rendererFactory.createRenderer(this.dom, {
                        id: '-1',
                        encapsulation: ViewEncapsulation.None,
                        styles: [],
                        data: {}
                    });
                    canonical = document.querySelector("link[rel='" + urlRel + "']");
                    head = this.dom.head;
                    if (head === null) {
                        throw new Error('<head> not found within DOCUMENT.');
                    }
                    if (!!canonical) {
                        renderer.removeChild(head, canonical);
                    }
                }
                catch (e) {
                    console.error('Error within linkService : ', e);
                }
                return [2 /*return*/];
            });
        });
    };
    TitleMetadataService.ngInjectableDef = i0.defineInjectable({ factory: function TitleMetadataService_Factory() { return new TitleMetadataService(i0.inject(i1.DOCUMENT), i0.inject(i2.Title), i0.inject(i2.Meta), i0.inject(i3.Router), i0.inject(i3.ActivatedRoute), i0.inject(i0.RendererFactory2)); }, token: TitleMetadataService, providedIn: "root" });
    return TitleMetadataService;
}());
export { TitleMetadataService };
