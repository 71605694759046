import { ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from './google-analytics.service';
import * as i0 from "@angular/core";
import * as i1 from "./google-analytics.service";
var GlobalErrorHandlerService = /** @class */ (function () {
    function GlobalErrorHandlerService(injector, googleAnalyticsService) {
        this.injector = injector;
        this.googleAnalyticsService = googleAnalyticsService;
    }
    GlobalErrorHandlerService.prototype.handleError = function (error) {
        var route = this.injector.get(Router);
        var errorMessage = error.message ? ("Message: " + error.message + ", Stack: " + error.stack) : (error.stack ? error.stack : error.toString());
        if (error.message.indexOf('$instance.slick is not a function') > -1) {
            route.navigate([route.url]);
        }
        this.googleAnalyticsService.emitExceptions("URL: " + route + " Error: " + errorMessage);
    };
    GlobalErrorHandlerService.ngInjectableDef = i0.defineInjectable({ factory: function GlobalErrorHandlerService_Factory() { return new GlobalErrorHandlerService(i0.inject(i0.INJECTOR), i0.inject(i1.GoogleAnalyticsService)); }, token: GlobalErrorHandlerService, providedIn: "root" });
    return GlobalErrorHandlerService;
}());
export { GlobalErrorHandlerService };
