import { Injectable, RendererFactory2, Inject, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class BingAnalyticsService {

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document) {

  }

  pagePathTracker(pageURL: string) {
    const pageTracker = `window.uetq.push('event', 'page_view', { 'page_path': '${pageURL}' });`;
    this.constructUETBingScript(pageTracker);
  }

  orderTracker(orderDetails: any) {
    const orderTracker = `window.uetq.push('event', '', {
      'revenue_value': '${orderDetails.orderTotal}',
      'currency': '${Constants.currencyUSD}'
    });`;
    this.constructUETBingScript(orderTracker);
  }


  constructUETBingScript(customTrackerCode: string) {
    const text = `(function(w,d,t,r,u){
          var f,n,i;w[u]=w[u]||[],f=function(){
          var o={
            ti:"5213831"
          };
          o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
        },
        n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){
          var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),
          n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
        })(window,document,"script","//bat.bing.com/bat.js","uetq");

        window.uetq = window.uetq || [];
        ${customTrackerCode}`;
    this.addScript(text);
  }

  addScript(scriptText: string): void {
    this.removeScript();
    // Add the listener and script
    const head = this.document.head;
    if (head === null) {
      throw new Error('<head> not found within DOCUMENT.');
    }
    const renderer = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });

    const script = renderer.createElement('script');
    script.text = scriptText;
    script.id = 'uetConversionTracker';
    renderer.appendChild(head, script);
  }

  removeScript(): void {
    try {
      const viewItemScript = this.document.getElementById('uetConversionTracker');
      if (viewItemScript) {
        viewItemScript.parentNode.removeChild(viewItemScript);
      }
    } catch (e) {
      console.error('Error within script removing : ', e);
    }
  }
}
