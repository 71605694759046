<div class="login-wrapper">
    <a type="button" id="first-tab-index" tabindex="1" ></a>
    <div class="page-header pt-8 pb-10 lgpy-12">

        <div class="container container-padding mx-auto flex justify-center">
            <h1 class="text-center uppercase text-5xl font-display page-header__title lines-centered-red font-medium w-72">
                <div tabindex="0" class="w-72">Create Account</div>
            </h1>
        </div>

    </div>

    <div class="container container-padding mx-auto py-1 pb-20">

        <form class="max-w-md mx-auto bg-white rounded-lg sm:px-10 pt-10 pb-6" [formGroup]="registerForm">
            <!-- Email -->
            <div class="mb-3 input-group">
                <input type="text" class="input w-full" placeholder="Email" autocomplete="email" id="txtEmailId" formControlName="Username"
                    aria-describedby="email-required-errors" [ngClass]="{'input--error': registerForm.controls.Username.invalid && registerForm.controls.Username.touched}">
                <label for="txtEmailId" class="input-faux-label">Email*</label>
                <div id="email-required-errors" *ngIf="registerForm.controls.Username.errors">
                    <!-- <div class="input-error-message">This email address already exists</div> -->
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.Username.errors.required && registerForm.controls.Username.touched">Email
                        is
                        required.</span>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.Username.errors.email || (registerForm.controls.Username.errors.pattern && registerForm.controls.Username.touched)">
                        Please enter
                        valid email.</span>
                </div>
            </div>
            <!-- First Name -->
            <div class="mb-3 input-group">
                <input type="text" appNoWhitespace class="input w-full" placeholder="First Name" autocomplete="given-name" id="txtFirstName"
                    [ngClass]="{'input--error': registerForm.controls.givenName.invalid && registerForm.controls.givenName.touched}"
                    formControlName="givenName" aria-describedby="first-name-required-errors">
                <label for="txtFirstName" class="input-faux-label">First Name*</label>
                <div id="first-name-required-errors" *ngIf="registerForm.controls.givenName.errors">
                    <div class="input-error-message"
                        *ngIf="registerForm.controls.givenName.errors.required && registerForm.controls.givenName.touched">
                        First Name is required.
                    </div>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.givenName.errors.maxlength && registerForm.controls.givenName.touched">First
                        Name is invalid. (Maximum 80 characters allowed)
                    </span>
                </div>
            </div>
            <!-- Last Name -->
            <div class="mb-3 input-group">
                <input type="text" appNoWhitespace class="input w-full" placeholder="Last Name" autocomplete="family-name" id="txtLastName"
                    [ngClass]="{'input--error': registerForm.controls.familyName.invalid && registerForm.controls.familyName.touched}"
                    formControlName="familyName" aria-describedby="last-name-required-errors">
                <label for="txtLastName" class="input-faux-label">Last Name*</label>
                <div id="last-name-required-errors" *ngIf="registerForm.controls.familyName.errors">
                    <div class="input-error-message"
                        *ngIf="registerForm.controls.familyName.errors.required && registerForm.controls.familyName.touched">
                        Last Name is required.
                    </div>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.familyName.errors.maxlength && registerForm.controls.familyName.touched">Last
                        Name is invalid. (Maximum 80 characters allowed)
                    </span>
                </div>
            </div>
            <!-- Phone Number -->
            <div class="mb-3 input-group">
                <input type="text" class="input w-full" placeholder="Phone Number" autocomplete="tel" mask="(000)-000-0000" id="txtPhoneNumber"
                    [ngClass]="{'input--error': registerForm.controls.phoneNumber.invalid && registerForm.controls.phoneNumber.touched}"
                    formControlName="phoneNumber" aria-describedby="phone-number-required-errors">
                <label for="txtPhoneNumber" class="input-faux-label">Phone Number*</label>
                <div id="phone-number-required-errors" *ngIf="registerForm.controls.phoneNumber.errors">
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.phoneNumber.errors.required && registerForm.controls.phoneNumber.touched">Phone
                        Number is required.</span>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.phoneNumber.errors.pattern && registerForm.controls.phoneNumber.touched">
                        Invalid Phone Number.
                    </span>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.phoneNumber.errors.maxlength && registerForm.controls.phoneNumber.touched">
                        Maximum 10 digits required.
                    </span>
                </div>
            </div>
            <!-- Zip Code -->
            <div class="mb-3 input-group">
                <input type="text" class="input w-full" placeholder="Zip Code" appZipCodeValidator="zipCode" mask="00000" autocomplete="postal-code" 
                    id="txtZipCode" [ngClass]="{'input--error': registerForm.controls.zipCode.invalid && registerForm.controls.zipCode.touched}"
                    formControlName="zipCode" aria-describedby="zip-code-required-errors">
                <label for="txtZipCode" class="input-faux-label">Zip Code*</label>
                <div id="zip-code-required-errors" *ngIf="registerForm.controls.zipCode.errors">
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.zipCode.errors.required && registerForm.controls.zipCode.touched">Zip
                        Code
                        is
                        required.</span>
                    <span class="input-error-message"
                        *ngIf="!registerForm.controls.zipCode.errors.required && registerForm.controls.zipCode.errors.pattern && registerForm.controls.zipCode.touched">Enter
                        numbers
                        only.</span>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.zipCode.errors.minlength && registerForm.controls.zipCode.touched">Minimum
                        5
                        characters required.
                    </span>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.zipCode.errors.maxlength && registerForm.controls.zipCode.touched">Maximum
                        5
                        characters required.
                    </span>

                </div>
            </div>
            <!-- Password -->
            <div class="mb-3 input-group">
                <input type="password" class="input w-full" placeholder="Password" formControlName="Password" autocomplete="new-password" validateEqual="ConfirmPassword"
                    reverse="true" [ngClass]="{'input--error': registerForm.controls.Password.invalid && registerForm.controls.Password.touched}"
                    aria-describedby="password-required-errors" id="txtPassword">
                <label for="txtPassword" class="input-faux-label">Password*</label>
                <div id="password-required-errors" *ngIf="registerForm.controls.Password.errors">
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.Password.errors.required && registerForm.controls.Password.touched">Password
                        is
                        required.
                    </span>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.Password.errors.minlength && registerForm.controls.Password.touched">Minimum
                        8
                        characters required.
                    </span>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.Password.errors.maxlength && registerForm.controls.Password.touched">
                        Maximum 98 characters are allowed.
                    </span>
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.Password.errors.pattern && registerForm.controls.Password.touched">Password
                        should contain uppercase, lowercase, number and special characters.
                    </span>
                </div>
            </div>
            <!-- Confirm Password -->
            <div class="mb-3 input-group">
                <input type="password" class="input w-full" placeholder="Confirm Password" formControlName="ConfirmPassword" autocomplete="new-password" 
                    validateEqual="Password" [ngClass]="{'input--error': registerForm.controls.ConfirmPassword.invalid && registerForm.controls.ConfirmPassword.touched}"
                    aria-describedby="confirm-password-required-errors" id="txtConfirmPassword">
                <label for="txtConfirmPassword" class="input-faux-label">Confirm Password*</label>
                <div id="confirm-password-required-errors" *ngIf="registerForm.controls.ConfirmPassword.errors">
                    <span class="input-error-message"
                        *ngIf="registerForm.controls.ConfirmPassword.errors.required && registerForm.controls.ConfirmPassword.touched">
                        Confirm Password is required.</span>
                    <span class="input-error-message"
                        *ngIf="!registerForm.controls.ConfirmPassword.errors.required && registerForm.controls.ConfirmPassword.errors && registerForm.controls.ConfirmPassword.touched">
                        Passwords do not match.</span>
                </div>
            </div>

            <label class="mt-8 mb-3 flex justify-center text-sm">
                <input type="checkbox" class="mt-1 focus" formControlName="notifyJDNews">
                <div class="ml-3">Notify me about JD news, offers, and events</div>
            </label>

            <div class="mt-4">
                <button class="button button--border block mb-3 w-full md:w-auto mx-auto" (click)="register()"
                    id="btnCreateAccount" [disabled]="!registerForm.valid">Create Account</button>
            </div>

            <p class="text-center"><a class="no-underline" [routerLink]="['/login']">Back to Login</a>
            </p>
            <!-- <p class="text-center"><a href="#" class="no-underline" [routerLink]="['/login']">Back to Login</a></p> -->
        </form>

        <p class="text-center text-xs text-black py-3 max-w-xs mx-auto">By creating an account, you represent that you
            have read and agree to our
            <a [href]="termsPolicy" target="_blank">Terms</a> and our <a [href]="privacyPolicy" target="_blank">Privacy
                Policy</a>
        </p>
    </div>
</div>