import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VersionCheckService = /** @class */ (function () {
    function VersionCheckService(http) {
        this.http = http;
        // this will be replaced by actual hash post-build.js
        this.currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    }
    // Checks in every set frequency the version of frontend application
    // @param url
    // @param {number} frequency - in milliseconds, defaults to 30 minutes
    VersionCheckService.prototype.initVersionCheck = function (url, frequency) {
        var _this = this;
        if (frequency === void 0) { frequency = 1000 * 60 * 30; }
        setInterval(function () {
            _this.checkVersion(url);
        }, frequency);
        this.checkVersion(url);
    };
    // Will do the call and check if the hash has changed or not
    // @param url
    VersionCheckService.prototype.checkVersion = function (url) {
        var _this = this;
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .subscribe(function (response) {
            var hash = response.hash;
            var hashChanged = _this.hasHashChanged(_this.currentHash, hash);
            // If new version, do something
            if (hashChanged) {
                console.log('New build detected - Application reloading');
                window.location.reload();
                // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                // for an example: location.reload();
            }
            // store the new hash so we wouldn't trigger versionChange again
            // only necessary in case you did not force refresh
            _this.currentHash = hash;
        }, function (err) {
            console.error(err, 'Could not get version');
        });
    };
    // Checks if hash has changed.
    // This file has the JS hash, if it is a different one than in the version.json
    // we are dealing with version change
    // @param currentHash
    // @param newHash
    // @returns {boolean}
    VersionCheckService.prototype.hasHashChanged = function (currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }
        return currentHash !== newHash;
    };
    VersionCheckService.ngInjectableDef = i0.defineInjectable({ factory: function VersionCheckService_Factory() { return new VersionCheckService(i0.inject(i1.HttpClient)); }, token: VersionCheckService, providedIn: "root" });
    return VersionCheckService;
}());
export { VersionCheckService };
