import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
var StorageService = /** @class */ (function () {
    function StorageService(platform) {
        this.platform = platform;
    }
    // Gets item from storage
    StorageService.prototype.getItem = function (key) {
        var value = localStorage.getItem(key);
        try {
            value = JSON.parse(value);
        }
        catch (e) {
            if (e === 'Unexpected token e in JSON at position 0') {
                value = localStorage.getItem(key);
            }
        }
        return value;
    };
    // Sets item from storage
    StorageService.prototype.setItem = function (key, value) {
        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
    };
    // Remove item from storage
    StorageService.prototype.removeItem = function (key) {
        localStorage.removeItem(key);
    };
    // Clear all items
    StorageService.prototype.clear = function () {
        localStorage.clear();
    };
    // Gets item from session storage
    StorageService.prototype.sessionGetItem = function (key) {
        if (isPlatformBrowser(this.platform)) {
            var value = sessionStorage.getItem(key);
            try {
                value = JSON.parse(value);
            }
            catch (e) {
                if (e === 'Unexpected token e in JSON at position 0') {
                    value = sessionStorage.getItem(key);
                }
            }
            return value;
        }
    };
    // Sets item from storage
    StorageService.prototype.sessionSetItem = function (key, value) {
        if (isPlatformBrowser(this.platform)) {
            if (typeof value !== 'string') {
                value = JSON.stringify(value);
            }
            sessionStorage.setItem(key, value);
        }
    };
    // Remove item from storage
    StorageService.prototype.sessionRemoveItem = function (key) {
        if (isPlatformBrowser(this.platform)) {
            sessionStorage.removeItem(key);
        }
    };
    // Clear all items
    StorageService.prototype.sessionClear = function () {
        if (isPlatformBrowser(this.platform)) {
            sessionStorage.clear();
        }
    };
    StorageService.ngInjectableDef = i0.defineInjectable({ factory: function StorageService_Factory() { return new StorageService(i0.inject(i0.PLATFORM_ID)); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
