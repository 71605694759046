import { Injectable, Inject, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class TitleMetadataService {

  constructor(
    @Inject(DOCUMENT) private dom,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private rendererFactory: RendererFactory2) { }

  async updateTitle(title?: string) {
    if (!title) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)).subscribe((event) => {
            if (event) {
              this.titleService.setTitle(event.title);
            }
          });
    } else {
      this.titleService.setTitle(title);
    }
  }

  async updateMetaInfo(url?: string, author?: string) {
    this.metaService.updateTag({ name: 'og:url', content: url });
    this.metaService.updateTag({ name: 'author', content: author });
  }

  async updateMetaKeywords(contentStr: string) {
    this.metaService.updateTag({ name: 'keyword', content: contentStr });
  }

  async addMetaTags(nameStr: string, contentStr: string) {
    this.metaService.addTag({ name: nameStr, content: contentStr });
  }

  async updateDescription(desc?: string) {
    if (!desc) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) { route = route.firstChild; }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)).subscribe((event) => {
            this.metaService.updateTag({ name: 'description', content: event.description });
            this.removeCanonicalLink('canonical');
          });
    } else {
      this.metaService.updateTag({ name: 'description', content: desc });
    }
  }

  async createCanonicalURL(url?: string, paginationInfo?: any) {
    let canURL = '';
    if (url.indexOf('page=') > 0) {
      if (url.indexOf('?') > 0) {
        url = url.split('?')[0];
        if (paginationInfo && paginationInfo.pageNumber !== 1) {
          url += `?page=${paginationInfo.pageNumber}`;
        }
      } else {
        url = url;
      }
    } else if (url.indexOf('?') > 0) {
      url = url.split('?')[0];
    } else if (url.indexOf('#') > 0) {
      url = url.split('#')[0];
    }
    // if (url === undefined || url === '') {
    //   canURL = this.dom.URL;
    // } else {
    const baseURL: string = Constants.domainURL; // this.dom.location.origin;
    canURL = `${baseURL}${url}`.toLowerCase();
    // }

    this.removeCanonicalLink('canonical');
    const renderer = this.rendererFactory.createRenderer(this.dom, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });

    const link = renderer.createElement('link');
    const head = this.dom.head;
    const tag: LinkDefinition = { rel: 'canonical', href: canURL };
    // const selector = this._parseSelector(tag);

    if (head === null) {
      throw new Error('<head> not found within DOCUMENT.');
    }

    Object.keys(tag).forEach((prop: string) => {
      return renderer.setAttribute(link, prop, tag[prop]);
    });

    renderer.appendChild(head, link);
  }

  async createRelURL(urlRel: string, url?: string, pageNumber?: any) {
    this.removeCanonicalLink(urlRel);
    let canURL = '';

    if (url.indexOf('page=') > 0) {
      if (url.indexOf('?') > 0) {
        url = url.split('?')[0];
      } else {
        url = url;
      }
    }

    if (pageNumber === 0) {
      return false;
    } else {
      if (pageNumber === 1) {
        url = url;
      } else {
        url += `?page=${pageNumber}`;
      }
    }
    const baseURL: string = Constants.domainURL;
    canURL = `${baseURL}${url}`.toLowerCase();
    const renderer = this.rendererFactory.createRenderer(this.dom, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });

    const link = renderer.createElement('link');
    const head = this.dom.head;
    const tag: LinkDefinition = { rel: urlRel, href: canURL };
    // const selector = this._parseSelector(tag);

    if (head === null) {
      throw new Error('<head> not found within DOCUMENT.');
    }

    Object.keys(tag).forEach((prop: string) => {
      return renderer.setAttribute(link, prop, tag[prop]);
    });

    renderer.appendChild(head, link);
  }

  async removeCanonicalLink(urlRel: string) {
    try {
      const renderer = this.rendererFactory.createRenderer(this.dom, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });
      const canonical = document.querySelector(`link[rel=\'${urlRel}\']`);
      const head = this.dom.head;

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }
      if (!!canonical) {
        renderer.removeChild(head, canonical);
      }
    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }
}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
