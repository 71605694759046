<div [ngClass]="{'login-wrapper': !modalView}">
    <div [ngClass]="{'page-header pt-8 pb-10 lg:py-12': !modalView}">
        <div class="container mx-auto" [ngClass]="{'container-padding': !modalView,'pb-10': modalView}">
            <div class="text-center uppercase text-5xl font-display page-header__title lines-centered-red">Login</div>
        </div>
    </div>

    <div class="container mx-auto py-1" [ngClass]="{'container-padding pb-20': !modalView}">
        <form [formGroup]="loginForm" class="max-w-md mx-auto bg-white rounded-lg p-5" [ngClass]="{'md:p-10': !modalView}">
            <div *ngIf="isLoginMessage" class="flex items-center bg-blue text-white text-sm font-bold mb-5 px-4" role="alert">
                <i class="fas fa-info pl-1 pr-2 align-center"></i>
                <p class="align-center">Please login with your updated password.</p>
            </div>
            <h4 class="text-center mb-6" *ngIf="isValidUser">
                Thanks for creating a new account! Your email address has been verified and you can now login.
            </h4>
            <div class="mb-3 input-group">
                <label class="input-label">Email</label>
                <input type="email" class="input w-full" placeholder="Email" formControlName="Username" id="txtEmailId2" [ngClass]="{'input--error': loginForm.controls.Username.invalid && loginForm.controls.Username.touched}" required>
                <div class="input-faux-label">Email</div>
                <div *ngIf="loginForm.controls.Username.errors">
                    <span class="input-error-message" *ngIf="loginForm.controls.Username.errors.required && loginForm.controls.Username.touched">Email is
            required.
          </span>
                    <span class="input-error-message" *ngIf="loginForm.controls.Username.errors.email || (loginForm.controls.Username.errors.pattern && loginForm.controls.Username.touched)">
            Please enter valid
            email.</span>
                </div>
            </div>

            <div class="mb-3 input-group">
                <label class="input-label">Password</label>
                <input type="password" class="input w-full" placeholder="Password" formControlName="Password" required [ngClass]="{'input--error': loginForm.controls.Password.invalid && loginForm.controls.Password.touched}">
                <div class="input-faux-label">Password</div>
                <div class="input-error-message" *ngIf="loginForm.controls.Password.invalid && loginForm.controls.Password.touched">Password is required.</div>
            </div>
            <p class="text-center mb-6"><a href="javascript:void(0)" class="no-underline" (click)="forgotPassword()">Forgot
          Password?</a></p>

            <div class="lg:flex justify-between mt-10">
                <button class="button button--fill block w-full mb-3 lg:inline-block lg:mr-2" (click)="login()" [disabled]="!loginForm.valid">Login</button>

                <button class="button button--border block mb-3 w-full lg:inline-block lg:ml-2" (click)="createAccount()">Create
          Account</button>
            </div>
        </form>

        <p class="text-center text-xs text-black py-3 max-w-xs mx-auto">By signing into your account, you represent that you have read and agree to our
            <a [href]="termsPolicy" target="_blank">Terms</a> and our <a [href]="privacyPolicy" target="_blank">Privacy Policy</a></p>

    </div>
    <!--/container-->

</div>

<!--/checkout-wrapper-->