import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from '../shared/constants';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UseraccountService {

  constructor(private http: HttpClient) { }

  // Order List Api Start


  // Get Orderlist Resources of logged in user from Jamestown GET Request
  getOrderlists(paginationQuery?: string): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/orders/skus?${paginationQuery}`);
  }

  // Create Customer account of logged in user from Jamestown POST Request
  createCustomer(body: any): Observable<any> {
    return this.http.post(`${Constants.baseURL}/accounts/account/customer`, body);
  }

  // Wishlist APIs Start

  // Get Wishlist Resources of logged in user from Jamestown GET Request
  getWishlists(paginationQuery?: string): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/wishlists?${paginationQuery}`);
  }

  // Get Wishlist Details of logged in user from Jamestown POST Request
  getWishlistsSKUS(wishlistId: number, paginationQuery?: string, sortingQuery?: string): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/wishlists/${wishlistId}/skus?${paginationQuery}&${sortingQuery}`);
  }

  // Get Wishlist Details of logged in user from Jamestown POST Request
  getWishlistsSKUSWithHashId(wishlistHashId: number): Observable<any> {
    return this.http.get(`${Constants.baseURL}/wishlists/${wishlistHashId}`);
  }

  // Get orderlist Details of logged in user from Jamestown POST Request
  getOrderlistsSKUS(paginationQuery?: string, sortingQuery?: string): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/orders/skus?${paginationQuery}&${sortingQuery}`);
  }

  // Get Wishlist Details of logged in user from Jamestown POST Request
  getWishlistsResources(wishlistId: number): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/wishlists/${wishlistId}`);
  }

  // Create Wishlist Resources of logged in user from Jamestown POST Request
  createWishlist(body: any): Observable<any> {
    return this.http.post(`${Constants.baseURL}/accounts/account/customer/wishlists/`, body);
  }

  // Updates Wishlist Resources of logged in user from Jamestown POST Request
  updateWishlist(body: any): Observable<any> {
    return this.http.put(`${Constants.baseURL}/accounts/account/customer/wishlists/${body.id}`, body);
  }

  // Update Wishlist SKU Resource of logged in user from Jamestown POST Request
  updateWishlistSKU(body: any, wishlistId: number): Observable<any> {
    return this.http.put(`${Constants.baseURL}/accounts/account/customer/wishlists/${wishlistId}/skus/${body.id}`, body);
  }
  // Deletes a SKU resource from Wishlist using Jamestown DELETE Request
  deleteWishlistSKUItem(wishlistId: number, removeItemId: number): Observable<any> {
    return this.http.delete(`${Constants.baseURL}/accounts/account/customer/wishlists/${wishlistId}/skus/${removeItemId}`);
  }

  // Deletes a Wishlist using Jamestown DELETE Request
  deleteWishlist(wishlistId: number): Observable<any> {
    return this.http.delete(`${Constants.baseURL}/accounts/account/customer/wishlists/${wishlistId}`);
  }

  // Get Wishlist Details of logged in user from Jamestown POST Request
  getSharedWishlistsPeople(wishlistId: number): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/wishlists/${wishlistId}/sharedWishlists`);
  }

  // Deletes a Shared Wishlist person using Jamestown DELETE Request
  deleteSharedWishlistsPerson(wishlistId: number, personWishlistHashId: string): Observable<any> {
    return this.http.delete(`
    ${Constants.baseURL}/accounts/account/customer/wishlists/${wishlistId}/sharedWishlists/${personWishlistHashId}
    `);
  }

  // Deletes a Shared Wishlist person using Jamestown DELETE Request
  addSharedWishlistsPerson(wishlistId: number, data: any): Observable<any> {
    return this.http.post(`${Constants.baseURL}/accounts/account/customer/wishlists/${wishlistId}/sharedWishlists/`, data);
  }

  // Wishlist APIs End

  // Address APIs Start
  getCustomerAddresses(params = {}): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/shippingAddresses`, { params });
  }

  getDefaultCustomerAddresses(): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/shippingAddresses/default`);
  }

  addCustomerAddresses(address: any): Observable<any> {
    return this.http.post(`${Constants.baseURL}/accounts/account/customer/shippingAddresses/`, address);
  }

  updateCustomerAddresses(address: any): Observable<any> {
    return this.http.put(`${Constants.baseURL}/accounts/account/customer/shippingAddresses/${address.id}`, address);
  }

  deleteCustomerAddresses(address: any): Observable<any> {
    return this.http.delete(`${Constants.baseURL}/accounts/account/customer/shippingAddresses/${address.id}`);
  }
  // Address APIs End

  // Payments APIs Start
  getDefaultPayment(): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/paymentMethods/default`);
  }

  getAllPayment(sortParam: string = ''): Observable<any> {
    return this.http.get(`${Constants.baseURL}/accounts/account/customer/paymentMethods${sortParam}`);
  }

  addPayments(payment: any): Observable<any> {
    return this.http.post(`${Constants.baseURL}/accounts/account/customer/paymentMethods/`, payment);
  }

  updatePayment(payment: any): Observable<any> {
    return this.http.put(`${Constants.baseURL}/accounts/account/customer/paymentMethods/${payment.id}`, payment);
  }

  removePayments(paymentId: any): Observable<any> {
    return this.http.delete(`${Constants.baseURL}/accounts/account/customer/paymentMethods/${paymentId}`);
  }

  // Set card type
  setCardType(cardNumber: string): string {
    cardNumber = cardNumber.replace(/ /g, '').trim();
    if (Constants.amexpRegEx.test(cardNumber)) {
      return Constants.americanExpress.key;
    }
    if (Constants.visaRegEx.test(cardNumber)) {
      return Constants.visa.key;
    }
    if (Constants.mastercardRegEx.test(cardNumber)) {
      return Constants.masterCard.key;
    }
    if (Constants.discovRegEx.test(cardNumber)) {
      return Constants.discoverCard.key;
    }
  }

  validateCreditCardNumber(cardNumber: AbstractControl) {
    let isValid = false;
    const ccNum = cardNumber.value.replace(/ /g, '').trim();
    if (Constants.visaRegEx.test(ccNum)) {
      isValid = true;
    } else if (Constants.mastercardRegEx.test(ccNum)) {
      isValid = true;
    } else if (Constants.amexpRegEx.test(ccNum)) {
      isValid = true;
    } else if (Constants.discovRegEx.test(ccNum)) {
      isValid = true;
    }

    if (!isValid) {
      cardNumber.setErrors({ ccNumber: true });
    }
    return isValid;
  }
  // Payments APIs End

}
