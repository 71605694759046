import { Injectable, Inject, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Constants } from '../constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchSpringScriptsService {
  searchSpringPageLoaded = new BehaviorSubject('');

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document) { }

  addViewItemScript(productDetails: any) {
    const text = `try {
            var product_code = "${productDetails.id}";
            IntelliSuggest.init({siteId:'${Constants.searchSpringSiteId}', context:'Product/' + product_code, seed:product_code});
            IntelliSuggest.viewItem({sku:product_code});
        } catch(err) {}`;
    this.addScript(text);
  }

  addCartTracking(skus: any) {
    if (skus && skus.length > 0) {
      let text = `try{
              IntelliSuggest.init({siteId:'${Constants.searchSpringSiteId}', context:'Basket', seed:[`;
      skus.forEach(sku => {
        text += `'${sku.product.id}',`;
      });
      text += `]});`;

      // Loop through products in cart
      skus.forEach(sku => {
        text += `IntelliSuggest.haveItem({ sku: "${sku.product.id}", qty: "${sku.quantity}", price: "${sku.sellPrice}" });`;
      });

      text += `IntelliSuggest.inBasket({});
          } catch(err) { }`;
      this.addScript(text);
    }
  }

  addOrderTracking(orderDetails): void {
    let text = `try{
      IntelliSuggest.init({siteId:'${Constants.searchSpringSiteId}'});`;

    // Loop through products in cart
    if (orderDetails && orderDetails.skus && orderDetails.skus.length > 0) {
      orderDetails.skus.forEach(sku => {
        text += `IntelliSuggest.haveItem({ sku: "${sku.product.id}", qty: "${sku.quantity}", price: "${sku.sellPrice}" });`;
      });
    }

    text += `IntelliSuggest.inSale({
              orderId:"${orderDetails.id}", // The Order ID (optional)
              total:"${orderDetails.orderTotal ? orderDetails.orderTotal.toFixed(2) : 0}", // The total order amount (optional)
              city:"${orderDetails.shippingAddress.city}", // Customer"s shipping address City (optional)
              state:"${orderDetails.shippingAddress.state}", // Customer"s shipping address State (optional)
              country:"${Constants.countryUS}" // Customer"s shipping address 2-letter Country Code (optional)
          });
        } catch(err) { }`;
    this.addScript(text);
  }

  addIntellisuggestProductTrackClick(element: any, data: any, signature: any): void {
    const escapeFn = encodeURIComponent || escape;
    if (document.images) {
      let apiUrl;
      if ('https:' === location.protocol) {
        apiUrl = Constants.searchSpringURL;
      } else {
        apiUrl = Constants.searchSpringURL;
      }
      const imgTag = new Image();
      imgTag.src = apiUrl + '/track/track.json?d=' + data + '&s=' + signature + '&u=' + escapeFn(element.href) + '&r=' + escapeFn(document.referrer);
    }
  }

  addScript(scriptText: string): void {
    this.removeScript();
    // Add the listener and script
    const head = this.document.head;
    if (head === null) {
      throw new Error('<head> not found within DOCUMENT.');
    }
    const renderer = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });

    const script = renderer.createElement('script');
    script.text = scriptText;
    script.id = 'ssIntellisuggestTrackingScript';
    renderer.appendChild(head, script);
  }

  removeScript(): void {
    try {
      const viewItemScript = this.document.getElementById('ssIntellisuggestTrackingScript');
      if (viewItemScript) {
        viewItemScript.parentNode.removeChild(viewItemScript);
      }
    } catch (e) {
      console.error('Error within script removing : ', e);
    }
  }
}
