import { InjectionToken, FactoryProvider } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var WINDOW = new InjectionToken('window');
var ɵ0 = function () { return window; };
var windowProvider = {
    provide: WINDOW,
    useFactory: ɵ0
};
export var WINDOW_PROVIDERS = [
    windowProvider
];
var GeneralOperationsService = /** @class */ (function () {
    function GeneralOperationsService(http) {
        this.http = http;
        this.InterceptorSkipLoader = 'X-Skip-Loader';
    }
    GeneralOperationsService.prototype.capitalLetter = function (str) {
        var stringArray = str.split(' ');
        for (var i = 0, x = stringArray.length; i < x; i++) {
            stringArray[i] = stringArray[i][0].toUpperCase() + stringArray[i].substr(1);
        }
        return stringArray.join(' ');
    };
    GeneralOperationsService.prototype.getToasterNotifications = function (url) {
        var headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
        return this.http.get(Constants.baseURL + "/toasters" + url, { headers: headers });
    };
    GeneralOperationsService.ngInjectableDef = i0.defineInjectable({ factory: function GeneralOperationsService_Factory() { return new GeneralOperationsService(i0.inject(i1.HttpClient)); }, token: GeneralOperationsService, providedIn: "root" });
    return GeneralOperationsService;
}());
export { GeneralOperationsService };
export { ɵ0 };
