import { of } from 'rxjs';
import { StorageService } from '../shared/services/storage.service';
import { CartService } from '../cart/cart.service';
import { mergeMap, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../shared/services/storage.service";
import * as i2 from "../cart/cart.service";
var CheckoutResolveService = /** @class */ (function () {
    function CheckoutResolveService(storageService, cartService) {
        this.storageService = storageService;
        this.cartService = cartService;
    }
    CheckoutResolveService.prototype.resolve = function (route, state) {
        var _this = this;
        if (route.queryParams && route.queryParams.prop === 'viewInvoice') {
            return of(true);
        }
        if (this.storageService.getItem('zipCode')) {
            this.zipCode = this.storageService.getItem('zipCode');
        }
        var cartId = this.storageService.getItem('cartId');
        if (this.storageService.getItem('idToken')) {
            return this.cartService.getShoppingCartById(cartId).pipe(mergeMap(function (shoppingCartDetails) {
                return _this.getShippingMethod(shoppingCartDetails);
            }));
        }
        else {
            if (cartId) {
                return this.cartService.getShoppingCartByIdWithoutAccount(cartId).pipe(mergeMap(function (shoppingCartDetails) {
                    return _this.getShippingMethod(shoppingCartDetails);
                }));
            }
        }
    };
    // Get Shipping methods
    CheckoutResolveService.prototype.getShippingMethod = function (shoppingCartDetails) {
        if (shoppingCartDetails && shoppingCartDetails.skus && shoppingCartDetails.skus.length > 0) {
            var subTotal = shoppingCartDetails.skus.map(function (product) { return product.quantity * product.sellPrice; }).reduce(function (a, b) { return a + b; });
            var skusList = shoppingCartDetails.skus.map(function (product) { return product.id; });
            var skusQuantity = shoppingCartDetails.skus.map(function (product) { return product.quantity; });
            if (this.zipCode) {
                var queryUrl = "postal_code=" + this.zipCode + "&order_subtotal=" + subTotal + "&skus=" + skusList.toString() + "&sku_qty=" + skusQuantity.toString();
                if (this.storageService.getItem('idToken')) {
                    queryUrl += "&email_address=" + this.storageService.getItem('currentUser').email;
                }
                return this.cartService.getShippingMethods(queryUrl).pipe(map(function (orderSummaryDetails) {
                    return {
                        shoppingCartData: shoppingCartDetails,
                        orderSummary: orderSummaryDetails
                    };
                }));
            }
            else {
                return of({
                    shoppingCartData: shoppingCartDetails,
                    orderSummary: {}
                });
            }
        }
        else {
            return of(true);
        }
    };
    CheckoutResolveService.ngInjectableDef = i0.defineInjectable({ factory: function CheckoutResolveService_Factory() { return new CheckoutResolveService(i0.inject(i1.StorageService), i0.inject(i2.CartService)); }, token: CheckoutResolveService, providedIn: "root" });
    return CheckoutResolveService;
}());
export { CheckoutResolveService };
