import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, Subscription } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { LoaderService } from './loader.service';
import { Constants } from '../constants';
import { NotificationService } from './notification.service';
import { LoginService } from './../../login/login.service';
import { StorageService } from './storage.service';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from './google-analytics.service';
import { isString, isObject, isArray } from 'util';
import { SearchSpringScriptsService } from './search-spring-scripts.service';
import { v4 as uuidv4 } from 'uuid';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor, OnDestroy {
  searchSpringPageId: any;
  searchSpringSessionId = uuidv4();
  searchSpringUserId = uuidv4();

  userSub: Subscription;
  InterceptorSkipLoader = 'X-Skip-Loader';

  counter = 0;
  constructor(
    @Inject(DOCUMENT) private dom,
    @Inject(PLATFORM_ID) private platform: any,
    private router: Router,
    private loaderService: LoaderService,
    private notificationService: NotificationService,
    private loginService: LoginService,
    private storageService: StorageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private searchSpringService: SearchSpringScriptsService
  ) {
    this.searchSpringService.searchSpringPageLoaded.subscribe(res => {
      this.searchSpringPageId = res;
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.counter = 0;
    if (request.headers.has(this.InterceptorSkipLoader)) {
      const headers = request.headers.delete(this.InterceptorSkipLoader);
      request = request.clone({ headers });
    } else if (request.url.indexOf('validateZipcode') >= 0) {
    } else {
      this.counter += 1;
      this.loaderService.show();
    }

    // Added condition to handle sending specific headers to SearchSpring(/search/search.json Api)
    if (request.url.indexOf(`${Constants.searchSpringURL}/search/search.json`) >= 0) {
      let cookies: any = {};
      if (isPlatformBrowser(this.platform)) {
        document.cookie.split(';').map((el: any) => {
          el = el.split('=');
          cookies[el[0].trim()] = (el[1] || '').trim()
        });
        request = request.clone({
          setHeaders: {
            'searchspring-page-load-id': this.searchSpringPageId,
            'searchspring-session-id': cookies.ssSessionIdNamespace || this.searchSpringSessionId,
            'searchspring-user-id': cookies.ssUserId || this.searchSpringUserId
          }
        });
      } else {
        request = request.clone({
          setHeaders: {
            'searchspring-page-load-id': this.searchSpringPageId,
            'searchspring-session-id': this.searchSpringSessionId,
            'searchspring-user-id': this.searchSpringUserId
          }
        });
      }
    }

    // Added condition to handle sending specific headers to SearchSpring
    if (request.url.indexOf(Constants.searchSpringURL) >= 0 || request.url.indexOf(Constants.autocompleteSearchSpringURL) >= 0 || request.url.indexOf(Constants.searchSpringIntelliSuggestURL) >= 0 || request.url.indexOf('authorize.net') >= 0) {
      request = request.clone({
        setHeaders: {
          // 'Content-Type': 'application/json',
          Accept: '*/*'
        }
      });
      if (request.url.indexOf(Constants.searchSpringURL) >= 0 || request.url.indexOf(Constants.autocompleteSearchSpringURL) >= 0 || request.url.indexOf(Constants.searchSpringIntelliSuggestURL) >= 0) {
        request = request.clone({
          setParams: {
            domain: this.dom.URL
          }
        });
      }
    } else if (request.url.indexOf('applepayValidate') >= 0) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          UserID: Constants.UserID
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          UserID: Constants.UserID,
          SiteID: Constants.SiteID,
          Accept: '*/*'
        }
      });
      if (request.url.indexOf('accounts') >= 0 || request.url.indexOf('shippingMethod') >= 0) {
        if (this.storageService.getItem('idToken')) {
          request = request.clone({
            setHeaders: {
              Authorization: this.storageService.getItem('idToken')
            }
          });
        }
      }
    }

    if (request.body) {
      for (const [key, objVal] of Object.entries(request.body)) {
        if (key.includes('_links')) {
          delete request.body[key];
        } else if (isObject(objVal)) {
          this.iterateObject(objVal);
        } else if (isArray(objVal)) {
          this.iterateArray(objVal);
        } else {
          if (isString(objVal) && objVal.toString().indexOf('://') > 0) {
            delete request.body[key];
          }
        }
      }
    }
    if (isPlatformBrowser(this.platform) && !window.navigator.onLine) {
      setTimeout(() => {
        this.loaderService.hide();
      }, 2000)

      return throwError(`Your request failed. Please make sure you're connected to the internet and try again.`);
    } else {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage: any;

          if (error.error instanceof ErrorEvent) {
            // client error
            errorMessage = error.error.message;
          } else {
            // server error
            if (error.status === 400) {
              // Possibilities of this error
              // Cors OR Being redirected to a url which is guarded by auths
              errorMessage = error.error.message;
              this.notificationService.showError(errorMessage);
            } else if (error.status === 401) {
              errorMessage = `${error.error.message}. Please relogin` || error.statusText;
              this.router.navigate(['login']);
            } else if (error.status === 404) {
              errorMessage = error;
            } else {
              errorMessage = error.error.message || error.message;
            }
          }
          this.googleAnalyticsService.emitExceptions(`Error Type: HTTP Error: ${errorMessage}`);
          if (isPlatformServer(this.platform)) {
            console.log(`Response Error Message  - ${errorMessage}, Date: ${new Date()}`);
          }

          if (request.url.indexOf('accounts') >= 0) {
            this.userSub = this.loginService.user.subscribe(user => {
              if (!!user) {
                // ...
              }
            });
            return throwError(errorMessage);
          }
          return throwError(errorMessage);
        }), finalize(() => {
          this.counter -= 1;
          this.loaderService.hide();
        })
      );
    }
  }

  iterateObject(requestObj: any): any {
    for (const [key, value] of Object.entries(requestObj)) {
      if (key.includes('_links')) {
        delete requestObj[key];
      } else if (isObject(value)) {
        this.iterateObject(value);
      } else if (isArray(value)) {
        this.iterateArray(value);
      } else {
        if (isString(value) && value.toString().indexOf('://') > 0) {
          delete requestObj[key];
        }
      }
    }
  }

  iterateArray(requestArr: any): any {
    requestArr.forEach(obj => {
      if (obj.value.includes('_links')) {
        delete obj[obj.key];
      } else if (isObject(obj)) {
        this.iterateObject(obj);
      } else if (isArray(obj)) {
        this.iterateArray(obj);
      } else {
        if (isString(obj) && obj.indexOf('://') > 0) {
          delete requestArr[obj];
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    // if (this.routeSub) {
    //   this.routeSub.unsubscribe();
    // }
  }
}
