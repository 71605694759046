import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../shared/constants';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from '../shared/services/storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/services/storage.service";
var CartService = /** @class */ (function () {
    function CartService(http, storageService) {
        this.http = http;
        this.storageService = storageService;
        this.cartValue = new BehaviorSubject(0.00);
        this.getCartDetail = new BehaviorSubject(false);
        this.onCartChange = new BehaviorSubject(false);
        this.InterceptorSkipLoader = 'X-Skip-Loader';
    }
    // Update cart value
    CartService.prototype.updateCartValue = function (value) {
        this.cartValue.next(value);
    };
    CartService.prototype.getCartValue = function () {
        return this.cartValue.asObservable();
    };
    // Update cart Detail Page
    CartService.prototype.updateCartDetailPage = function (value) {
        this.getCartDetail.next(value);
    };
    CartService.prototype.getCartDetailUpdateStatus = function () {
        return this.getCartDetail.asObservable();
    };
    // When a zip code is entered into this text box --> “Enter your zip code to calculate”
    // If logged in call on load
    CartService.prototype.getShippingMethods = function (body) {
        return this.http.get(Constants.baseURL + "/shippingMethod?" + body);
    };
    CartService.prototype.getShippingMethodsPromise = function (body) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            resolve(_this.http.get(Constants.baseURL + "/shippingMethod?" + body).toPromise());
        });
    };
    CartService.prototype.getShoppingCartList = function () {
        var _this = this;
        return this.http.get(Constants.baseURL + "/accounts/account/customer/shoppingCarts").pipe(map(function (data) {
            return _this.handleCartNotFound(data);
        }));
    };
    CartService.prototype.getShoppingCartById = function (cartId) {
        var _this = this;
        return this.http.get(Constants.baseURL + "/accounts/account/customer/shoppingCarts/" + cartId).pipe(map(function (data) {
            return _this.handleCartNotFound(data);
        }));
    };
    CartService.prototype.getShoppingCartByIdWithoutInterceptor = function (cartId) {
        var _this = this;
        var headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
        return this.http.get(Constants.baseURL + "/accounts/account/customer/shoppingCarts/" + cartId, { headers: headers }).pipe(map(function (data) {
            return _this.handleCartNotFound(data);
        }));
    };
    CartService.prototype.getShoppingCartListWithoutAccount = function () {
        var _this = this;
        return this.http.get(Constants.baseURL + "/shoppingCarts").pipe(map(function (data) {
            return _this.handleCartNotFound(data);
        }));
    };
    CartService.prototype.getShoppingCartByIdWithoutAccount = function (cartId) {
        var _this = this;
        return this.http.get(Constants.baseURL + "/shoppingCarts/" + cartId).pipe(map(function (data) {
            return _this.handleCartNotFound(data);
        }));
    };
    CartService.prototype.getShoppingCartByIdWithoutAccountWithoutInterceptor = function (cartId) {
        var _this = this;
        var headers = new HttpHeaders().set(this.InterceptorSkipLoader, '');
        return this.http.get(Constants.baseURL + "/shoppingCarts/" + cartId, { headers: headers }).pipe(map(function (data) {
            return _this.handleCartNotFound(data);
        }));
    };
    CartService.prototype.getShoppingCartSkusList = function (cartId) {
        var _this = this;
        return this.http.get(Constants.baseURL + "/accounts/account/customer/shoppingCarts/" + cartId + "/skus").pipe(map(function (data) {
            return _this.handleCartNotFound(data);
        }));
    };
    CartService.prototype.getShoppingCartSkusById = function (cartId, skuId) {
        var _this = this;
        return this.http.get(Constants.baseURL + "/accounts/account/customer/shoppingCarts/" + cartId + "/skus/" + skuId).pipe(map(function (data) {
            return _this.handleCartNotFound(data);
        }));
    };
    CartService.prototype.handleCartNotFound = function (data) {
        if (data && data.message === Constants.resourceNotFound) {
            this.storageService.removeItem('cartId');
        }
        return data;
    };
    CartService.prototype.createShoppingCartWithoutAccount = function (body) {
        return this.http.post(Constants.baseURL + "/shoppingCarts", body);
    };
    CartService.prototype.createShoppingCartSkuWithoutAccount = function (cartId, body) {
        return this.http.post(Constants.baseURL + "/shoppingCarts/" + cartId + "/skus", body);
    };
    CartService.prototype.createShoppingCart = function (body) {
        return this.http.post(Constants.baseURL + "/accounts/account/customer/shoppingCarts", body);
    };
    CartService.prototype.createShoppingCartSku = function (cartId, body) {
        return this.http.post(Constants.baseURL + "/accounts/account/customer/shoppingCarts/" + cartId + "/skus", body);
    };
    CartService.prototype.updateShoppingCartByIdWithoutAccount = function (cartId, body) {
        return this.http.put(Constants.baseURL + "/shoppingCarts/" + cartId, body);
    };
    CartService.prototype.addAllToShoppingCartByIdWithoutAccount = function (cartId, body) {
        return this.http.put(Constants.baseURL + "/shoppingCarts/" + cartId + "/skus", body);
    };
    CartService.prototype.updateShoppingCartSkusByIdWithoutAccount = function (cartId, skuId, body) {
        return this.http.put(Constants.baseURL + "/shoppingCarts/" + cartId + "/skus/" + skuId, body);
    };
    CartService.prototype.updateShoppingCartById = function (cartId, body) {
        return this.http.put(Constants.baseURL + "/accounts/account/customer/shoppingCarts/" + cartId, body);
    };
    CartService.prototype.addAllToShoppingCartById = function (cartId, body) {
        return this.http.put(Constants.baseURL + "/accounts/account/customer/shoppingCarts/" + cartId + "/skus", body);
    };
    CartService.prototype.updateShoppingCartSkusById = function (cartId, skuId, body) {
        return this.http.put(Constants.baseURL + "/accounts/account/customer/shoppingCarts/" + cartId + "/skus/" + skuId, body);
    };
    // “Remove” link:
    CartService.prototype.deleteShoppingCartSkusById = function (cartId, skuId) {
        return this.http.delete(Constants.baseURL + "/accounts/account/customer/shoppingCarts/" + cartId + "/skus/" + skuId);
    };
    CartService.prototype.deleteShoppingCartSkusByIdWithoutAccount = function (cartId, skuId) {
        return this.http.delete(Constants.baseURL + "/shoppingCarts/" + cartId + "/skus/" + skuId);
    };
    CartService.prototype.getSearchSpringTrending = function (seed) {
        var seedUrl = seed.replace(/,/g, '&seed=');
        // return this.http.get(`${Constants.searchSpringURL}/recommend/trending.json?siteId=${Constants.searchSpringSiteId}&limit=${Constants.searchSpringResultsLimit}`);
        return this.http.get(Constants.searchSpringURL + "/recommend?siteId=" + Constants.searchSpringSiteId + "&seed=" + seedUrl + "&limit=" + Constants.searchSpringResultsLimit);
    };
    CartService.ngInjectableDef = i0.defineInjectable({ factory: function CartService_Factory() { return new CartService(i0.inject(i1.HttpClient), i0.inject(i2.StorageService)); }, token: CartService, providedIn: "root" });
    return CartService;
}());
export { CartService };
