/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.template; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["aria-modal", "true"], ["class", "modal"], ["id", "modal"], ["role", "dialog"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "active": 0, "modal--bottom": 1, "modal--cancel-bar": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-bg js-close-modal"], ["id", "modal-backdrop"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "active": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal"; var currVal_1 = _ck(_v, 2, 0, _co.active, _co.isModalBottom, _co.isModalCancelBar); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_co.template; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.template; _ck(_v, 6, 0, currVal_3); var currVal_4 = "modal-bg js-close-modal"; var currVal_5 = _ck(_v, 9, 0, _co.active); _ck(_v, 8, 0, currVal_4, currVal_5); }, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalComponent, [], null, null)], null, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i3.ModalComponent, View_ModalComponent_Host_0, { active: "active", isModalCancelBar: "isModalCancelBar", isModalBottom: "isModalBottom", template: "template" }, {}, ["*"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
