import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
    private injector: Injector,
    private googleAnalyticsService: GoogleAnalyticsService) {
  }

  handleError(error: Error) {
    const route = this.injector.get(Router);
    const errorMessage = error.message ? (`Message: ${error.message}, Stack: ${error.stack}`) : (error.stack ? error.stack : error.toString());

    if (error.message.indexOf('$instance.slick is not a function') > -1) {
      route.navigate([route.url]);
    }
    this.googleAnalyticsService.emitExceptions(`URL: ${route} Error: ${errorMessage}`);
  }
}
