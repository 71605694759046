import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeoLdSchemaService {
  ldSchema: Subject<object> = new Subject<object>();

  constructor() { }

  applySEOLDSchema(event: object): void {
    this.ldSchema.next(event);
  }
}
