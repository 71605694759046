import { NavigationCancel } from '@angular/router';
import { Constants } from '../constants';
import { StorageService } from './storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./storage.service";
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService(storageService) {
        this.storageService = storageService;
    }
    // create our event emitter to send our data to Google Analytics
    GoogleAnalyticsService.prototype.emitEvent = function (eventName, eventCategoryValue, eventActionValue, eventLabelValue, eventValueValue) {
        try {
            ga('send', eventName, { eventCategory: eventCategoryValue, eventLabel: eventLabelValue, eventAction: eventActionValue, eventValue: eventValueValue });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to send our Page Load and Navigation Timings to Google Analytics
    GoogleAnalyticsService.prototype.emitGTagEvent = function (eventName, navigationName, eventValue) {
        try {
            gtag('event', eventName, { name: navigationName, value: eventValue });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to Update Page browse activity on Google Analytics
    GoogleAnalyticsService.prototype.emitPageBrowseActivity = function (eventName, urlAfterRedirects) {
        try {
            ga('set', eventName, urlAfterRedirects);
            ga('send', 'pageview');
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to Update Page path activity on Google Analytics
    GoogleAnalyticsService.prototype.emitPagePathActivity = function (urlAfterRedirects) {
        try {
            gtag('config', Constants.googleAnalytics.gaMasurementId, { page_path: urlAfterRedirects });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to send Purchase Event Tracking data to Google Analytics
    GoogleAnalyticsService.prototype.emitPurchaseEventTracking = function (eventName, orderData) {
        var itemsArray = [];
        var i = 0;
        orderData.skus.forEach(function (element) {
            i++;
            var skuData = {
                id: element.product.id,
                name: element.product.name,
                category: element.product.subcategory,
                variant: element.id,
                list_position: i,
                quantity: element.quantity,
                price: element.sellPrice
            };
            itemsArray.push(skuData);
        });
        try {
            gtag('event', eventName, {
                transaction_id: orderData.id,
                affiliation: 'Jamestown Distributors',
                value: orderData.orderTotal,
                checkout_option: orderData.paymentMethod ? orderData.paymentMethod.paymentTransaction.type : '',
                currency: 'USD',
                tax: orderData.salesTax,
                shipping: orderData.shipping.total + orderData.shipping.hazmatFee + orderData.shipping.rushOrderFee,
                checkout: orderData.salesTax,
                coupon: orderData.promoCode !== undefined ? orderData.promoCode : '',
                items: itemsArray
            });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
        this.emitOrderConversionTracking('conversion', orderData);
    };
    // create our event emitter to send Purchase Event Tracking data to Google Analytics
    GoogleAnalyticsService.prototype.emitOrderConversionTracking = function (eventName, orderData) {
        var subTotal = orderData.skus.map(function (product) { return product.quantity * product.sellPrice; }).reduce(function (a, b) { return a + b; });
        try {
            gtag('event', eventName, {
                send_to: 'AW-1072569469/zOu8CMT7PhD9uLj_Aw',
                value: subTotal,
                currency: Constants.currencyUSD,
                transaction_id: orderData.id
            });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to send Product Detail Views data to Google Analytics
    GoogleAnalyticsService.prototype.emitProductDetailViews = function (productData, skuData) {
        var itemsArray = [
            {
                id: productData.id,
                name: productData.name,
                list_name: 'PDP Result',
                brand: productData.brand !== undefined ? productData.brand.name : '',
                category: (productData.breadcrumbs ? productData.breadcrumbs.category : '') + "/" + (productData.breadcrumbs ? productData.breadcrumbs.subcategory : ''),
                variant: skuData.name,
                list_position: 1,
                price: skuData.price.sellPrice
            }
        ];
        try {
            gtag('event', 'view_item', {
                items: itemsArray
            });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to send Product Impression Tracking data to Google Analytics
    GoogleAnalyticsService.prototype.emitProductImpressionTracking = function (productList) {
        var itemsArray = [];
        var i = 0;
        productList.forEach(function (element) {
            i++;
            var categoryString = element.ss_category !== undefined ? element.ss_category[0].replace('&gt;', '/') : '';
            var skuData = {
                id: element.uid,
                name: element.name,
                list_name: 'PLP Results',
                brand: element.brand,
                category: categoryString,
                variant: element.sku,
                list_position: i,
                price: element.ss_price
            };
            itemsArray.push(skuData);
        });
        try {
            gtag('event', 'view_item_list', {
                items: itemsArray
            });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to send Checkout Events Tracking data to Google Analytics
    GoogleAnalyticsService.prototype.emitCheckoutEventTracking = function (eventName, checkoutData, orderObj) {
        var itemsArray = [];
        var i = 0;
        checkoutData.skus.forEach(function (element) {
            i++;
            var categoryString = element.product.category !== undefined ? element.product.category + '/' + (element.product.subcategory !== undefined ? element.product.subcategory : '') : '';
            var skuData = {
                id: element.product.id,
                name: element.product.name,
                list_name: 'checkout_products',
                brand: element.product.name,
                category: categoryString,
                variant: element.id,
                list_position: i,
                quantity: element.quantity,
                price: element.sellPrice
            };
            itemsArray.push(skuData);
        });
        try {
            gtag('event', eventName, {
                items: itemsArray,
                coupon: "'" + ((orderObj && orderObj.promoCode !== undefined) ? orderObj.promoCode : '') + "'"
            });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to send Cart Events Tracking data to Google Analytics
    GoogleAnalyticsService.prototype.emitCartEventTracking = function (eventName, cartData) {
        var itemsArray = [];
        var i = 0;
        cartData.skus.forEach(function (element) {
            i++;
            var categoryString = element.product.category !== undefined ? element.product.category + '/' + (element.product.subcategory !== undefined ? element.product.subcategory : '') : '';
            var skuData = {
                id: element.product.id,
                name: element.name,
                list_name: 'Cart Items',
                category: categoryString,
                variant: element.id,
                list_position: i,
                quantity: element.quantity,
                price: element.sellPrice
            };
            itemsArray.push(skuData);
        });
        try {
            gtag('event', eventName, {
                items: itemsArray
            });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to send Checkout options data to Google Analytics
    GoogleAnalyticsService.prototype.emitCheckoutOptions = function (routeData, orderData) {
        var checkoutStepObj = {};
        if (routeData instanceof NavigationCancel) {
            return;
        }
        if (routeData.url.match('/checkout/info-shipping') !== null) {
            checkoutStepObj = {
                checkout_step: 1,
                value: 1
            };
        }
        if (routeData.url.match('/checkout/billing') !== null) {
            checkoutStepObj = {
                checkout_step: 1,
                checkout_option: orderData !== undefined ? orderData.shipping.method : '',
                value: 1
            };
        }
        if (routeData.url.match('/checkout/place-order') !== null) {
            checkoutStepObj = {
                checkout_step: 2,
                checkout_option: orderData.paymentMethod !== undefined ? orderData.paymentMethod.paymentTransaction.type : '',
                value: 2
            };
        }
        if (routeData.url.match('/checkout/order-confirmed') !== null) {
            if (orderData.paymentMethod && orderData.paymentMethod.paymentTransaction.type === Constants.paymentMode.authorizeNetCreditCard) {
                checkoutStepObj = {
                    checkout_step: 3,
                    value: 3
                };
            }
            else {
                checkoutStepObj = {
                    checkout_step: 2,
                    value: 2
                };
            }
        }
        try {
            gtag('event', 'set_checkout_option', checkoutStepObj);
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to send Content Event Tracking data to Google Analytics
    GoogleAnalyticsService.prototype.emitSelectContentEventTracking = function (productData, skuData) {
        var routeUrl = this.storageService.getItem('prevURL');
        var navigationExtra = this.storageService.getItem('navigationExtra');
        var itemsArray = [
            {
                id: productData.id,
                name: productData.name,
                list_name: 'PDP Result',
                brand: productData.brand !== undefined ? productData.brand.name : '',
                category: (productData.breadcrumbs ? productData.breadcrumbs.category : '') + "/" + (productData.breadcrumbs ? productData.breadcrumbs.subcategory : ''),
            }
        ];
        if (navigationExtra && navigationExtra.indexOf('search') > -1) {
            itemsArray[0].list_name = navigationExtra;
        }
        else if (routeUrl) {
            if (routeUrl.indexOf('/product-detail') > -1 && navigationExtra) {
                itemsArray[0].list_name = "PDP (" + navigationExtra + ")";
            }
            else if (routeUrl.indexOf('/product/brand') > -1) {
                itemsArray[0].list_name = 'brand page';
            }
            else if (routeUrl.indexOf('/product/') > -1) {
                itemsArray[0].list_name = 'PLP';
            }
            else if (routeUrl.indexOf('/useraccount/orders') > -1) {
                itemsArray[0].list_name = 'my orders';
            }
            else if (routeUrl.indexOf('/useraccount/wishlist-details') > -1 && navigationExtra) {
                itemsArray[0].list_name = "wishlist detail (" + navigationExtra + ")";
            }
            else if (routeUrl.indexOf('/useraccount/wishlist') > -1) {
                itemsArray[0].list_name = 'my wishlists';
            }
            else if (routeUrl.indexOf('/cart') > -1 && navigationExtra && navigationExtra.indexOf('shopping cart Things you might\'ve missed') > -1) {
                itemsArray[0].list_name = navigationExtra;
            }
            else if (routeUrl.indexOf('/cart') > -1) {
                itemsArray[0].list_name = 'shopping cart';
            }
            else if (routeUrl.indexOf('/home') > -1) {
                itemsArray[0].list_name = 'homepage';
            }
        }
        else {
            return;
        }
        try {
            gtag('event', 'select_content', {
                content_type: 'product',
                items: itemsArray
            });
        }
        catch (ex) {
            this.emitExceptions(ex, false);
        }
    };
    // create our event emitter to send Exception and Errors data to Google Analytics
    GoogleAnalyticsService.prototype.emitExceptions = function (errorData, isFatal) {
        if (isFatal === void 0) { isFatal = false; }
        try {
            gtag('event', 'exception', {
                description: errorData,
                fatal: isFatal
            });
        }
        catch (ex) {
        }
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.inject(i1.StorageService)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
