import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { NotificationService } from './../../shared/services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService } from './../../cart/cart.service';
import { StorageService } from './../../shared/services/storage.service';
import { LoaderService } from './../../shared/services/loader.service';
import { Constants } from './../../shared/constants';
import { UseraccountService } from './../../useraccount/useraccount.service';
import { GoogleAnalyticsService } from './../../shared/services/google-analytics.service';
import { CheckoutService } from './../../checkout/checkout.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnChanges {
  isValidUser: boolean = false;
  @Input() modalView: boolean = false;
  @Output() closeModal = new EventEmitter();
  showMessage: boolean = true;
  returnUrl: string = '';
  privacyPolicy: string = Constants.privacyPolicy;
  termsPolicy: string = Constants.termsPolicy;
  isLoginMessage: boolean = false;

  loginForm = this.fb.group({
    Username: ['', [Validators.required, Validators.email, Validators.pattern(Constants.emailPattern)]],
    Password: ['', Validators.required],
  });

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private router: Router,
    private cartService: CartService,
    private storageService: StorageService,
    private checkoutService: CheckoutService,
    private loaderService: LoaderService,
    private useraccountService: UseraccountService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.isValidUser = params.validateUser === 'Y' ? true : false;
      if (params.showLoginMessage !== undefined && params.showLoginMessage === 'true') {
        this.isLoginMessage = true;
      } else {
        this.isLoginMessage = false;
      }
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.modalView.currentValue === true) {
      this.ngOnInit();
    }
  }

  login() {
    if (this.loginForm.invalid) {
      return false;
    }
    this.loaderService.show();
    this.returnUrl = this.router.url;
    this.storageService.setItem('returnURL', this.returnUrl);
    this.loginService.login(this.loginForm.getRawValue()).then(
      response => {
        this.loaderService.hide();
        this.getAccountDetails();
        if (this.modalView) {
          this.closeModal.emit(this.modalView);
        }
      }, reject => {
        this.loaderService.hide();
        if (reject.code === 'UserNotConfirmedException') {
          this.notificationService.showInfo(reject.message, 'Information');
          // User not Confirmed
          this.loginService.resendConfirmationCode(this.loginForm.getRawValue()).then(
            res => {
              this.notificationService.showInfo('Verfication Link is sent. Please check your email', 'Information');
              if (this.modalView) {
                this.closeModal.emit(this.modalView);
              }
            }, rej => {
              this.notificationService.showError(rej.message);
            }
          );
        } else {
          if (reject.message === 'UserMigration failed with error Incorrect email address or password.') {
            this.notificationService.showError('Incorrect username or password');
          } else {
            this.notificationService.showError(reject.message);
          }
        }
      });
  }

  getAccountDetails(): void {
    this.loginService.getAccountDetails().subscribe(res => {
      if (res) {
        this.storageService.setItem('accountId', res.id ? res.id : '');
        this.storageService.setItem('firstName', res.firstName ? res.firstName : '');
        this.storageService.setItem('lastName', res.lastName ? res.lastName : '');
        this.storageService.setItem('customerId', res.customerId ? res.customerId : '');
        this.storageService.setItem('zipCode', res.zipCode ?  res.zipCode : '');
        this.storageService.setItem('subTotal', '0');
        this.getCartDetails();
      }
    }, error => {
      if (error.status === 404) {
        const userInfo = this.storageService.getItem('currentUser');
        const body = {
          userSub: userInfo.sub,
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          emailAddress: userInfo.email,
          phone: userInfo.phone_number.substr(1),
          zipCode: 13654
        };
        this.loginService.createAccount(body).subscribe(res => {
          this.storageService.setItem('accountId', res.id);
          this.storageService.setItem('customerId', res.customer_id);
          this.getCartDetails();
        }, err => {
          this.notificationService.showError(err);
        });
      } else {
        this.notificationService.showError(error);
      }
    });
  }

  getCartDetails() {
    const cartId = this.storageService.getItem('cartId');
    if (cartId) {
      this.cartService.getShoppingCartByIdWithoutAccount(cartId).subscribe(cartData => {
        this.getLoggedInUserCart(cartData);
      }, (error) => {
        this.notificationService.showError(error);
      });
    } else {
      this.getLoggedInUserCart(null);
    }
  }

  getLoggedInUserCart(cartData: any): void {
    this.cartService.getShoppingCartList().subscribe((response) => {
      if (response.content && response.content[0].skus) {
        if (response.content[0].skus.length) {
          const subtotal = response.content[0].skus.map(product => product.quantity * product.sellPrice).reduce((a, b) => a + b);
          this.cartService.updateCartValue(subtotal);
        }
        this.storageService.setItem('cartId', response.content[0].id);
        if (cartData) {
          this.addToCart(cartData);
        } else {
          this.navigateAfterLogin();
        }
      } else {
        this.storageService.removeItem('cartId');
        if (cartData && cartData.skus && cartData.skus.length > 0) {
          if (this.storageService.getItem('customerId')) {
            this.createCustomerCart(cartData);
          } else {
            this.createCustomer(cartData);
          }
        } else {
          this.navigateAfterLogin();
        }
      }
      this.cartService.onCartChange.next(true);
    }, (error) => {
      this.storageService.removeItem('cartId');
    });
  }

  addToCart(cartArr): void {
    if (cartArr && cartArr.skus && cartArr.skus.length > 0) {
      const cartId = this.storageService.getItem('cartId');
      if (cartId) {
        this.cartService.addAllToShoppingCartById(cartId, cartArr).subscribe(response => {
          this.notificationService.showSuccess('Added to your Shopping Cart');
          this.googleAnalyticsService.emitCartEventTracking(Constants.googleAnalytics.eventTags.addToCart, cartArr);
          const subtotal = response.skus.map(product => product.quantity * product.sellPrice).reduce((a, b) => a + b);
          this.cartService.updateCartValue(subtotal);
          if (this.router.url.indexOf("/checkout/info-shipping") !== -1) {
            this.checkoutService.updateCartAfterLogin(true);
          }
          this.navigateAfterLogin();
        }, error => {
          this.notificationService.showError(error);
        });
      } else {
        // Create Cart if does not exist
        this.createCustomerCart(cartArr);
      }
    } else {
      this.navigateAfterLogin();
    }
  }

  // Create Cart if does not exist
  createCustomerCart(cartData): void {
    const cartArray = {
      skus: []
    };
    cartData.skus.forEach(element => {
      const obj = {
        id: element.id,
        quantity: element.quantity,
        uom: element.uom,
        uomQty: element.uomQty
      };
      cartArray.skus.push(obj);
    });
    this.cartService.createShoppingCart(cartArray).subscribe(res => {
      if (res && res.message) {
        this.getCartDetails();
      } else {
        this.storageService.setItem('cartId', res.id);
        this.getCartDetails();
        // this.addToCart(cartArray);
      }
    }, error => {
      this.notificationService.showError(error);
    });
  }

  createCustomer(cartArr): void {
    const body = {
      accountId: this.storageService.getItem('accountId')
    };
    this.useraccountService.createCustomer(body).subscribe(response => {
      this.createCustomerCart(cartArr);
      this.storageService.setItem('customerId', response.id);
    }, error => {
      this.notificationService.showError(error);
    });
  }

  navigateAfterLogin(): void {
    if (this.returnUrl === '/' && (this.storageService.getItem('returnURL') === null || this.storageService.getItem('returnURL') === '/')) {
      this.loginService.updateLoggedOutValue(true);
      this.router.navigate(['/home']);
    } else {
    this.cartService.onCartChange.next(true);
      this.returnUrl = this.storageService.getItem('returnURL');
      if (this.returnUrl.includes('login') || this.returnUrl.includes('register')) {
        this.router.navigate(['/home']);
      } else if (this.returnUrl.includes('/cart')) {

        setTimeout(() => {
          this.cartService.updateCartDetailPage(true);
        }, 500);
      } else {
        this.router.navigateByUrl(this.returnUrl);
      }

    }

  }

  createAccount(): void {
    this.router.navigate(['/register']);
    if (this.modalView) {
      this.closeModal.emit(false);
    }
  }

  forgotPassword(): void {
    this.router.navigate(['/forgotpassword'], { queryParams: { refPage: 'login' } });
    if (this.modalView) {
      this.closeModal.emit(false);
    }
  }
}
