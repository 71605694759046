/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgotpassword.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./forgotpassword.component";
import * as i5 from "../login.service";
import * as i6 from "@angular/router";
import * as i7 from "../../shared/services/notification.service";
var styles_ForgotpasswordComponent = [i0.styles];
var RenderType_ForgotpasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotpasswordComponent, data: {} });
export { RenderType_ForgotpasswordComponent as RenderType_ForgotpasswordComponent };
function View_ForgotpasswordComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "input-error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Email is required. "]))], null, null); }
function View_ForgotpasswordComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "input-error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please enter valid email. "]))], null, null); }
function View_ForgotpasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["id", "email-required-errors"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotpasswordComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotpasswordComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.forgotPasswordForm.controls.Username.errors.required && _co.forgotPasswordForm.controls.Username.touched); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.forgotPasswordForm.controls.Username.errors.email || (_co.forgotPasswordForm.controls.Username.errors.pattern && _co.forgotPasswordForm.controls.Username.touched)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ForgotpasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 35, "div", [["class", "login-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "a", [["id", "first-tab-index"], ["tabindex", "1"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "page-header pt-8 pb-10 lg:py-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "container container-padding mx-auto flex justify-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [["class", "text-center uppercase text-5xl font-display page-header__title lines-centered-red font-medium"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Forgot Password?"])), (_l()(), i1.ɵeld(7, 0, null, null, 28, "div", [["class", "container container-padding mx-auto py-1 pb-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 27, "form", [["class", "max-w-md mx-auto bg-white rounded-lg py-6 sm:px-10"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(10, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(12, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "text-center text-sm text-black pt-3 pb-6 max-w-xs mx-auto"], ["tabindex", "0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enter your email address below and we\u2019ll send a password reset code"])), (_l()(), i1.ɵeld(15, 0, null, null, 14, "div", [["class", "mb-3 input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "label", [["class", "input-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"])), (_l()(), i1.ɵeld(18, 0, null, null, 7, "input", [["aria-describedby", "email-required-errors"], ["autocomplete", "email"], ["class", "input w-full"], ["formControlName", "Username"], ["placeholder", "Email"], ["type", "email"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 21).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 21)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 21)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(20, { "input--error": 0 }), i1.ɵdid(21, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(23, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(25, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(26, 0, null, null, 1, "div", [["class", "input-faux-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotpasswordComponent_1)), i1.ɵdid(29, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 2, "div", [["class", "mt-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "button button--border block mb-3 w-full md:w-auto mx-auto focus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.forgotPassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Send Reset Code"])), (_l()(), i1.ɵeld(33, 0, null, null, 2, "p", [["class", "text-center mb-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "a", [["class", "no-underline"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.forgotPasswordForm; _ck(_v, 10, 0, currVal_7); var currVal_15 = "input w-full"; var currVal_16 = _ck(_v, 20, 0, (_co.forgotPasswordForm.controls.Username.invalid && _co.forgotPasswordForm.controls.Username.touched)); _ck(_v, 19, 0, currVal_15, currVal_16); var currVal_17 = "Username"; _ck(_v, 23, 0, currVal_17); var currVal_18 = _co.forgotPasswordForm.controls.Username.errors; _ck(_v, 29, 0, currVal_18); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 12).ngClassValid; var currVal_5 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 25).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 25).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 25).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 25).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 25).ngClassValid; var currVal_13 = i1.ɵnov(_v, 25).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 25).ngClassPending; _ck(_v, 18, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
export function View_ForgotpasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgotpassword", [], null, null, null, View_ForgotpasswordComponent_0, RenderType_ForgotpasswordComponent)), i1.ɵdid(1, 114688, null, 0, i4.ForgotpasswordComponent, [i3.FormBuilder, i5.LoginService, i2.Location, i6.ActivatedRoute, i6.Router, i7.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotpasswordComponentNgFactory = i1.ɵccf("app-forgotpassword", i4.ForgotpasswordComponent, View_ForgotpasswordComponent_Host_0, {}, {}, []);
export { ForgotpasswordComponentNgFactory as ForgotpasswordComponentNgFactory };
