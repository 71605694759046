<!-- Spinner -->
<div *ngIf="showLoader | async" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50" style="z-index:10000 !important">
    <span class="text-green-500 opacity-75 top-1/2 my-0 block absolute w-0 h-0" style="margin:0;position:absolute;left:50%;margin-right:-50%;transform:translate(-50%, -50%);top:34%;">
    <i class="fas fa-circle-notch fa-spin fa-5x"></i>
  </span>
</div>

<div *ngIf="showRouteLoader | async" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50" style="z-index:10000 !important">
    <span class="text-green-500 opacity-75 top-1/2 my-0 block absolute w-0 h-0" style="margin:0;position:absolute;left:50%;margin-right:-50%;transform:translate(-50%, -50%);top:34%;">
    <i class="fas fa-circle-notch fa-spin fa-5x"></i>
  </span>
</div>
