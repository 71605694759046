import { Directive, forwardRef, Attribute } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { LoginService } from './../../login/login.service';

@Directive({
  selector: '[appZipCodeValidator][formControlName],[appZipCodeValidator][formControl],[appZipCodeValidator][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => ZipCodeValidatorDirective), multi: true }]
})
export class ZipCodeValidatorDirective {
  constructor(
    @Attribute('appZipCodeValidator') public zipCode: string,
    private loginService: LoginService) { }

  validate(control: AbstractControl) {
    const element = control.root.get(this.zipCode);
    if (control.value && control.value.length === 5) {
      this.loginService.validateZipcode(control.value).subscribe(
        response => {
          if (response.validZipcode === 'N') {
            element.setErrors({ zipCode: false });
            // }
          } else {
            if (element.errors && !Object.keys(element.errors).length) {
              delete element.errors['zipCode'];
              element.setErrors(null);
            }
          }
        }, error => {
        });
    }
  }
}
