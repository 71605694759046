import { Directive, Output, Input, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[clickoutside]'
})
export class ClickOutsideDirective {

  @Output() clickoutside: EventEmitter<any>;
  @Input() enableWhiteListing: boolean;
  constructor() {
    this.clickoutside = new EventEmitter<any>();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    /**
     * If whitelisting is enabled this directive will only
     *  emit if the clicked element is outside and has a class whitelisted
     * In all the other cases it will emit if we click outside
     */
    if (this.enableWhiteListing) {
      const isWhiteListed = targetElement.classList.contains('whitelisted');
      if (isWhiteListed === false) {
        this.clickoutside.emit(true);
      } else { }
    } else {
      this.clickoutside.emit(true);
    }
  }

}
