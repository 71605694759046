import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
var NotificationService = /** @class */ (function () {
    function NotificationService(toastr) {
        this.toastr = toastr;
        this.notify = new Subject();
    }
    NotificationService.prototype.showSuccess = function (message, title) {
        if (title === void 0) { title = 'Success'; }
        var successToaster = this.toastr.success(this.roleAlertTitleMessage(title, message), title);
        this.anchorTagAriaHiddenAndPlayPause(successToaster);
    };
    NotificationService.prototype.showError = function (message, title) {
        if (title === void 0) { title = 'Error'; }
        var successToaster = this.toastr.error(this.roleAlertTitleMessage(title, message), title);
        this.anchorTagAriaHiddenAndPlayPause(successToaster);
    };
    NotificationService.prototype.showInfo = function (message, title) {
        if (title === void 0) { title = 'Info'; }
        var successToaster = this.toastr.info(this.roleAlertTitleMessage(title, message), title);
        this.anchorTagAriaHiddenAndPlayPause(successToaster);
    };
    NotificationService.prototype.showWarning = function (message, title) {
        if (title === void 0) { title = 'Warning'; }
        var successToaster = this.toastr.warning(this.roleAlertTitleMessage(title, message), title);
        this.anchorTagAriaHiddenAndPlayPause(successToaster);
    };
    NotificationService.prototype.hide = function () {
        this.toastr.clear();
    };
    NotificationService.prototype.notifyLogin = function (event) {
        this.notify.next(true);
    };
    NotificationService.prototype.roleAlertTitleMessage = function (title, message) {
        return "<div class=\"ngx-toastr-title-with-message\" tabindex=\"0\" role=\"alert\"><span>" + title + "</span> " + message + " </div> <div  class=\"ngx-toastr-play-pause\"><i class=\"fa fa-pause play-pause-icon\" aria-hidden=\"true\"></i></div>";
    };
    NotificationService.prototype.anchorTagAriaHiddenAndPlayPause = function (successToaster) {
        var _this = this;
        setTimeout(function () {
            var element = document.querySelector('.ngx-toastr-title-with-message');
            if (element) {
                element.querySelector('a').setAttribute('aria-hidden', 'true');
            }
        }, 100);
        setTimeout(function () {
            _this.toastTimeout = setTimeout(function () {
                _this.toastr.remove(successToaster.toastId);
            }, 5000);
            var playPauseIcon = document.querySelector('.ngx-toastr-play-pause .play-pause-icon');
            if (playPauseIcon) {
                playPauseIcon.addEventListener('click', function () {
                    if (playPauseIcon.classList.contains('fa-pause')) {
                        clearTimeout(_this.toastTimeout);
                        playPauseIcon.classList.replace('fa-pause', 'fa-play');
                    }
                    else if (playPauseIcon.classList.contains('fa-play')) {
                        _this.toastTimeout = setTimeout(function () {
                            _this.toastr.remove(successToaster.toastId);
                        }, 5000);
                        playPauseIcon.classList.replace('fa-play', 'fa-pause');
                    }
                });
            }
        });
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.ToastrService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
