import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { ConnectionServiceModule } from 'ng-connection-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { HttpErrorInterceptor } from './shared/services/http-error.interceptor.service';
import { AppInitializerService } from './shared/services/app-initializer.service';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { WINDOW_PROVIDERS } from './shared/services/general-operations.service';
import { BaseModule } from './base/base.module';

export function initializeAppFactory(appInitService: AppInitializerService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'jamestown-distributors-web'
    }),
    HttpClientModule,
    CookieModule.forRoot(),
    BrowserAnimationsModule,
    ConnectionServiceModule,
    BaseModule,
    AppRoutingModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitializerService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
