import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var SeoLdSchemaService = /** @class */ (function () {
    function SeoLdSchemaService() {
        this.ldSchema = new Subject();
    }
    SeoLdSchemaService.prototype.applySEOLDSchema = function (event) {
        this.ldSchema.next(event);
    };
    SeoLdSchemaService.ngInjectableDef = i0.defineInjectable({ factory: function SeoLdSchemaService_Factory() { return new SeoLdSchemaService(); }, token: SeoLdSchemaService, providedIn: "root" });
    return SeoLdSchemaService;
}());
export { SeoLdSchemaService };
