import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Constants } from '../shared/constants';
import { StorageService } from '../shared/services/storage.service';
import { Router } from '@angular/router';
import { NotificationService } from '../shared/services/notification.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/services/storage.service";
import * as i3 from "@angular/router";
import * as i4 from "../shared/services/notification.service";
var LoginService = /** @class */ (function () {
    function LoginService(http, storageService, router, notificationService) {
        this.http = http;
        this.storageService = storageService;
        this.router = router;
        this.notificationService = notificationService;
        this.user = new BehaviorSubject(null);
        this.isLoggedOutValue = new BehaviorSubject(false);
        this.showLoginModal = new BehaviorSubject({ isShowLoginModal: false, url: '' });
        this.baseURL = Constants.baseURL; // base URL to Endpoint
        var poolData = {
            UserPoolId: Constants.cognito.userPoolId,
            ClientId: Constants.cognito.clientId,
        };
        this.userPool = new CognitoUserPool(poolData);
    }
    // Update LoggedOut Value
    LoginService.prototype.updateLoggedOutValue = function (value) {
        this.isLoggedOutValue.next(value);
    };
    // Update LoggedOut Value
    LoginService.prototype.showLoginModalEvt = function (value) {
        this.showLoginModal.next(value);
    };
    LoginService.prototype.getLoggedOutValue = function () {
        return this.isLoggedOutValue.asObservable();
    };
    // Cognito Login
    LoginService.prototype.login = function (authenticationData) {
        var _this = this;
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        if (authenticationData && authenticationData.Username) {
            authenticationData.Username = authenticationData.Username.toLowerCase();
            var userData = {
                Username: authenticationData.Username,
                Pool: this.userPool,
            };
            var cognitoUser_1 = new CognitoUser(userData);
            // for user migrate flow, we need set this --Liang 20190430
            cognitoUser_1.setAuthenticationFlowType('USER_PASSWORD_AUTH');
            return new Promise(function (resolve, reject) {
                cognitoUser_1.authenticateUser(authenticationDetails, {
                    onSuccess: function (result) {
                        resolve(result);
                        _this.handleAuthentication(result, true);
                    },
                    onFailure: function (err) {
                        reject(err);
                    },
                });
            });
        }
    };
    // Cognito Logout
    LoginService.prototype.logout = function (navigateToLogin) {
        if (navigateToLogin === void 0) { navigateToLogin = false; }
        var currentUser = this.userPool.getCurrentUser();
        if (currentUser != null) {
            currentUser.username = currentUser.username.toLowerCase();
            var userData = {
                Username: currentUser.username,
                Pool: this.userPool,
            };
            var cognitoUser = new CognitoUser(userData);
            if (cognitoUser != null) {
                cognitoUser.signOut();
                this.storageService.clear();
                this.storageService.setItem('subTotal', '0');
                this.user.next(null);
            }
        }
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }
        this.tokenExpirationTimer = null;
        if (navigateToLogin) {
            this.router.navigate(['']);
        }
    };
    // Cognito get current user
    LoginService.prototype.getCurrentUser = function () {
        return this.userPool.getCurrentUser();
    };
    // Congnito Create Account
    LoginService.prototype.register = function (registerData) {
        var _this = this;
        var attributeList = [];
        if (registerData && registerData.Username) {
            registerData.Username = registerData.Username.toLowerCase();
        }
        var dataEmail = {
            Name: 'email',
            Value: registerData.Username,
        };
        var dataFamilyName = {
            Name: 'family_name',
            Value: registerData.familyName,
        };
        var dataGivenName = {
            Name: 'given_name',
            Value: registerData.givenName,
        };
        var dataPhoneNumber = {
            Name: 'phone_number',
            Value: registerData.phoneNumber,
        };
        var attributeEmail = new CognitoUserAttribute(dataEmail);
        var attributeFamilyName = new CognitoUserAttribute(dataFamilyName);
        var attributeGivenName = new CognitoUserAttribute(dataGivenName);
        var attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
        attributeList.push(attributeEmail);
        attributeList.push(attributeFamilyName);
        attributeList.push(attributeGivenName);
        attributeList.push(attributePhoneNumber);
        return new Promise(function (resolve, reject) {
            return _this.userPool.signUp(registerData.Username, registerData.Password, attributeList, null, function (err, result) {
                if (err) {
                    return reject(err);
                }
                var body = {
                    userSub: result.userSub,
                    firstName: registerData.givenName,
                    lastName: registerData.familyName,
                    emailAddress: registerData.Username,
                    phone: registerData.phoneNumber,
                    zipCode: registerData.zipCode
                };
                resolve(body);
            });
        });
    };
    // Creates Account in JD
    LoginService.prototype.createAccount = function (body) {
        // body = {
        //   'userSub': '82742b73-d282-4f63-9c76-9d118e7c2e8f',
        //   'firstName': 'Vinayak',
        //   'lastName': 'Bagi',
        //   'emailAddress': 'vinayak.bagi@silicus.com',
        //   'phone': '+14325551212',
        //   'zipCode': '34545'
        // }
        return this.http.post(this.baseURL + '/accounts/', body);
    };
    // Cognito Resend the Confirmation Code
    LoginService.prototype.resendConfirmationCode = function (authenticationData) {
        if (authenticationData && authenticationData.Username) {
            authenticationData.Username = authenticationData.Username.toLowerCase();
            var userData = {
                Username: authenticationData.Username,
                Pool: this.userPool,
            };
            var cognitoUser_2 = new CognitoUser(userData);
            return new Promise(function (resolve, reject) {
                cognitoUser_2.resendConfirmationCode(function (error, result) {
                    if (error) {
                        reject(error);
                    }
                    resolve(result);
                });
            });
        }
    };
    // Cognito Forgot Password
    LoginService.prototype.forgotPassword = function (authenticationData) {
        if (authenticationData && authenticationData.Username) {
            authenticationData.Username = authenticationData.Username.toLowerCase();
            var userData = {
                Username: authenticationData.Username,
                Pool: this.userPool,
            };
            var cognitoUser_3 = new CognitoUser(userData);
            // for user migrate flow, we need set this --Liang 20190430
            cognitoUser_3.setAuthenticationFlowType('USER_PASSWORD_AUTH');
            return new Promise(function (resolve, reject) {
                cognitoUser_3.forgotPassword({
                    onSuccess: function (result) {
                        resolve(result);
                    },
                    onFailure: function (err) {
                        reject(err);
                    },
                    inputVerificationCode: function () {
                        resolve();
                    }
                });
            });
        }
    };
    // Cognito Update Password by entering verification code
    LoginService.prototype.updatePassword = function (authenticationData) {
        if (authenticationData && authenticationData.Username) {
            authenticationData.Username = authenticationData.Username.toLowerCase();
            var userData = {
                Username: authenticationData.Username,
                Pool: this.userPool,
            };
            var cognitoUser_4 = new CognitoUser(userData);
            cognitoUser_4.setAuthenticationFlowType('USER_PASSWORD_AUTH');
            return new Promise(function (resolve, reject) {
                cognitoUser_4.confirmPassword(authenticationData.VerificationCode, authenticationData.Password, {
                    onSuccess: function () {
                        resolve();
                    },
                    onFailure: function (err) {
                        reject(err);
                    }
                });
            });
        }
    };
    // Update Password in Cognito
    LoginService.prototype.updateCognitoPassword = function (authenticationData) {
        var cognitoUser = this.userPool.getCurrentUser();
        return cognitoUser.getSession(function (err, session) {
            if (err) {
                return;
            }
            return new Promise(function (resolve, reject) {
                // tslint:disable-next-line: no-shadowed-variable
                return cognitoUser.changePassword(authenticationData.OldPassword, authenticationData.Password, function (err, result) {
                    if (err) {
                        return reject(err);
                    }
                    resolve(result);
                });
            });
        });
    };
    // Update User Account Info in Cognito
    LoginService.prototype.updateCognitoAccountInfo = function (authenticationData) {
        var _this = this;
        var attributeList = [];
        var userCognitoData = {
            Name: 'given_name',
            Value: authenticationData.firstName
        };
        var lastNameCognitoData = {
            Name: 'family_name',
            Value: authenticationData.lastName
        };
        var emailCognitoData = {
            Name: 'email',
            Value: authenticationData.emailAddress
        };
        var phoneCognitoData = {
            Name: 'phone_number',
            Value: authenticationData.phone
        };
        var zipCodeCognitoData = {
            Name: 'custom:zipCode',
            Value: authenticationData.zipCode
        };
        return new Promise(function (resolve, reject) {
            var attribute = new CognitoUserAttribute(userCognitoData);
            var attributeLastName = new CognitoUserAttribute(lastNameCognitoData);
            var attributeEmail = new CognitoUserAttribute(emailCognitoData);
            var attributePhone = new CognitoUserAttribute(phoneCognitoData);
            var attributeZipCode = new CognitoUserAttribute(zipCodeCognitoData);
            attributeList.push(attribute);
            attributeList.push(attributeLastName);
            attributeList.push(attributeEmail);
            attributeList.push(attributePhone);
            attributeList.push(attributeZipCode);
            var cognitoUser = _this.userPool.getCurrentUser();
            return cognitoUser.getSession(function (err, session) {
                if (err) {
                    return;
                }
                // tslint:disable-next-line: no-shadowed-variable
                return cognitoUser.updateAttributes(attributeList, function (err, result) {
                    if (err) {
                        if (err.message.indexOf(Constants.JDFakeException)) {
                            resolve(result);
                        }
                        else {
                            return reject(err);
                        }
                    }
                    resolve(result);
                });
            });
        });
    };
    // Get Account details from JD
    LoginService.prototype.getAccountDetails = function () {
        return this.http.get(this.baseURL + "/accounts/account");
    };
    // Creates Account in JD
    LoginService.prototype.editAccount = function (body) {
        return this.http.put(this.baseURL + '/accounts/account', body);
    };
    LoginService.prototype.validateZipcode = function (zipCode, state) {
        if (state === void 0) { state = undefined; }
        if (state !== undefined) {
            return this.http.get(this.baseURL + "/validateZipcode?zipcode=" + zipCode + "&statecd=" + state);
        }
        else {
            return this.http.get(this.baseURL + "/validateZipcode?zipcode=" + zipCode);
        }
    };
    LoginService.prototype.handleAuthentication = function (response, isFirstLoggedIn) {
        if (isFirstLoggedIn === void 0) { isFirstLoggedIn = false; }
        this.storageService.setItem('idToken', response.idToken.jwtToken);
        this.storageService.setItem('currentUser', response.idToken.payload);
        var expirationDate = new Date(response.idToken.payload.exp * 1000);
        var expiresIn = expirationDate.getTime() - new Date().getTime();
        var user = response.idToken.payload;
        user._tokenExpirationDate = expirationDate;
        user.token = response.idToken.jwtToken;
        user.isFirstLoggedIn = isFirstLoggedIn;
        this.autoLogout(expiresIn);
        this.user.next(user);
    };
    LoginService.prototype.autoLogin = function () {
        if (this.getCurrentUser()) {
            var userData = this.storageService.getItem('currentUser');
            this.user.next(userData);
            var date = new Date(userData.exp * 1000);
            var expirationDuration = date.getTime() - new Date().getTime();
            this.autoLogout(expirationDuration);
        }
    };
    LoginService.prototype.autoLogout = function (expirationDuration) {
        var _this = this;
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }
        if (expirationDuration > 0) {
            this.tokenExpirationTimer = setTimeout(function () {
                // Refresh the session using refreshToken
                _this.renewSessionWithRefreshToken();
            }, expirationDuration);
        }
        else {
            this.storageService.clear();
            this.storageService.setItem('subTotal', '0');
            this.user.next(null);
        }
    };
    LoginService.prototype.getActiveSession = function () {
        var userData = this.storageService.getItem('currentUser');
        var date = new Date(userData.exp * 1000);
        if (date < new Date()) {
            return false;
        }
        else {
            return true;
        }
    };
    LoginService.prototype.renewSessionWithRefreshToken = function () {
        var _this = this;
        var userData = {
            Username: this.storageService.getItem('currentUser').email,
            Pool: this.userPool,
        };
        var cognitoUser = new CognitoUser(userData);
        return new Promise(function (resolve, reject) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    _this.logout();
                    _this.storageService.setItem('returnURL', _this.router.url);
                    _this.notificationService.notifyLogin(_this.router.url);
                    reject();
                }
                else {
                    var refreshToken = session.getRefreshToken();
                    cognitoUser.refreshSession(refreshToken, function (error, result) {
                        if (error) {
                            _this.logout();
                            _this.storageService.setItem('returnURL', _this.router.url);
                            _this.notificationService.notifyLogin(_this.router.url);
                            reject();
                        }
                        else {
                            _this.handleAuthentication(result);
                            resolve();
                        }
                    });
                }
            });
        });
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient), i0.inject(i2.StorageService), i0.inject(i3.Router), i0.inject(i4.NotificationService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
